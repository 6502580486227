import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { ISingleContentBlockCardItem, ISingleContentBlockImage } from '@typings'

import { AcTitle } from '../ac-title/ac-title'
import { AcTypography } from '../ac-typography/ac-typography'
import { AcImage } from '../ac-image/ac-image'

import styles from './ac-card.module.scss'

export const AcCard = observer(
  ({ title, content, sort, image }: ISingleContentBlockCardItem) => {
    return (
      <Box
        className={styles['ac-card']}
        p={2}>
        <AcImage {...(image as ISingleContentBlockImage)} />
        <Box>
          <AcTitle
            level={3}
            color="primary">
            {title}
          </AcTitle>
          <AcTypography>{content}</AcTypography>
        </Box>
      </Box>
    )
  },
)
