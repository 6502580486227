import React, { useMemo } from 'react'
import { Typography } from '@mui/material'

interface IAcTypography {
  variant?: 'span' | 'paragraph'
  size?: 'xs' | 's' | 'm' | 'l'
  weight?: 'regular' | 'bold'
  children: string | React.ReactNode
  className?: string
  html?: boolean
  mt?: number
  mb?: number
}

export const AcTypography = ({
  variant = 'paragraph',
  size = 'm',
  weight = 'regular',
  children,
  className,
  html,
  mt,
  mb,
}: IAcTypography) => {
  const fontSize = useMemo(() => {
    switch (size) {
      case 'xs':
        return '0.75rem'

      case 's':
        return '0.875rem'

      case 'm':
        return '1rem'

      case 'l':
        return '1.125rem'

      default:
        return '1rem'
    }
  }, [size])

  return (
    <Typography
      gutterBottom
      paragraph={variant === 'paragraph'}
      variant={weight === 'bold' ? 'body2' : 'body1'}
      sx={{
        fontSize,
      }}
      className={className}
      mt={mt}
      mb={mb}>
      {html ? (
        <span dangerouslySetInnerHTML={{ __html: children as string }} />
      ) : (
        children
      )}
    </Typography>
  )
}
