import { useState, useEffect, useMemo, useRef } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Tooltip, lighten, hexToRgb, getContrastRatio } from '@mui/material'
import clsx from 'clsx'
import { AcButton } from '../index.core.components'
import type {
  ISingleTrack,
  ISingleTrackRow,
  ISingleMilestoneNode,
} from '@typings'
import { useStore } from '@hooks/use-store'
import { usePiwik } from '@hooks/use-piwik'

// Styles
import styles from './ac-milestone.module.scss'
import { useIsMd } from '@hooks/use-isMd'
import { ROUTE_PATHS } from '@constants'

interface IAcMilestone {
  track: ISingleTrack
  currentColumn: number
  currentRowIndex: number
  previousRow?: ISingleTrackRow
  currentRow?: ISingleTrackRow
  nextRow?: ISingleTrackRow
  milestone?: ISingleMilestoneNode
  isEmptyMilestone?: boolean
  hasPreviousMilestones: boolean
  hasAdditionalMilestones: boolean
  isFirstRow: boolean
  isLastRow: boolean
}

export const AcMilestone = ({
  track,
  currentColumn,
  currentRowIndex,
  previousRow,
  currentRow,
  nextRow,
  milestone,
  isEmptyMilestone,
  hasPreviousMilestones,
  hasAdditionalMilestones,
  isFirstRow,
  isLastRow,
}: IAcMilestone) => {
  const navigate = useNavigate()
  const { tracks } = useStore()
  const isMd = useIsMd()
  const { trackMilestoneClick } = usePiwik()

  const buttonRef = useRef<null | HTMLDivElement>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [buttonWidth, setButtonWidth] = useState(0)

  const colors = {
    main: hexToRgb(track.color),
    light: lighten(track.color, 0.9),
  }

  const acceptableContrast = getContrastRatio(colors.light, colors.main) > 4.5

  const allPreviousRows = track.rows.slice(0, currentRowIndex)

  const maxCharCount = isMd ? 15 : 12
  const characterCount = milestone?.name.length || 0
  const showTooltip = characterCount > maxCharCount && currentRow?.columns === 2
  const milestoneLabel = showTooltip
    ? milestone?.name.slice(0, maxCharCount) + '...'
    : milestone?.name

  const currentMilestoneActive = useMemo(() => {
    if (!tracks) return false
    if (tracks) {
      const { current_selected_track_id, current_selected_milestone_id } =
        tracks
      if (
        current_selected_track_id === track.slug &&
        current_selected_milestone_id === milestone?.slug
      ) {
        return true
      } else {
        return false
      }
    }
  }, [tracks])

  const hasMilestoneDirectlyAbove = useMemo(() => {
    return previousRow?.milestones.find(ms => ms.column === currentColumn)
      ? true
      : false
  }, [previousRow])

  const hasMilestoneDirectlyBelow = useMemo(() => {
    if (currentColumn === 2 && currentRowIndex == 0) return true
    return nextRow?.milestones.find(ms => ms.column === currentColumn)
      ? true
      : false
  }, [nextRow])

  const returnToTrack = useMemo(() => {
    let nextRowColumns = 1
    if (nextRow?.columns && nextRow.columns > 0) {
      nextRowColumns = nextRow.columns
    }
    return nextRowColumns < currentColumn
  }, [isEmptyMilestone, currentColumn, nextRow])

  const isFirstMilestoneInColumn = useMemo(() => {
    let noPrevMilestones = true

    allPreviousRows.forEach(row => {
      const sameColumn = row.milestones.find(ms => ms.column === currentColumn)
      if (sameColumn) {
        noPrevMilestones = false
      }
    })

    return noPrevMilestones
  }, [previousRow, currentColumn])

  const previousRowSubtrackMerging = useMemo(() => {
    let hasPrevSubtrack = false
    allPreviousRows.forEach(row => {
      const hasOtherColMilestones = row.milestones.find(
        ms => ms.column > currentColumn,
      )
      if (hasOtherColMilestones) {
        hasPrevSubtrack = true
      }
    })

    if (
      currentColumn === 1 &&
      currentRow?.columns === 1 &&
      previousRow?.columns === 2 &&
      hasPrevSubtrack
    )
      return true

    return false
  }, [])

  const milestoneClasses = useMemo(
    () =>
      clsx(
        styles['milestone'],
        isEmptyMilestone && styles['empty-milestone'],
        isFirstRow && styles['remove-before'],
        isLastRow && styles['remove-after'],
        isFirstMilestoneInColumn && styles['remove-before'],
        isFirstMilestoneInColumn &&
          isEmptyMilestone &&
          styles['remove-before-after'],
        hasMilestoneDirectlyAbove && styles['connect-before'],
        hasMilestoneDirectlyBelow && styles['connect-after'],
        isFirstRow && isEmptyMilestone && styles['remove-after'],
        !isEmptyMilestone && currentColumn === 1 && styles['first-col'],
        !hasPreviousMilestones && !isFirstRow && styles['remove-before-after'],
        !hasAdditionalMilestones && !isLastRow && styles['remove-before-after'],
        returnToTrack && styles['return-to-track'],
        previousRowSubtrackMerging &&
          !hasMilestoneDirectlyAbove &&
          styles['force-connect-before'],
      ),
    [
      hasMilestoneDirectlyAbove,
      hasMilestoneDirectlyBelow,
      isFirstMilestoneInColumn,
      milestone?.column,
      returnToTrack,
      hasPreviousMilestones,
      hasAdditionalMilestones,
      isFirstRow,
      isLastRow,
      previousRowSubtrackMerging,
    ],
  )

  const handleReSize = () => {
    const ref = buttonRef.current as HTMLDivElement
    if (ref) {
      // Needs a timeout to connect the line after DOM has been painted with new innerwidth
      setTimeout(() => {
        setOffsetLeft(ref.offsetLeft)
        setButtonWidth(ref.clientWidth)
      }, 50)
    }
  }

  const handleNavigation = () => {
    if (milestone?.id) {
      trackMilestoneClick(track, milestone)
      navigate(
        generatePath(ROUTE_PATHS.CONTENT_PAGE, {
          trackId: track.slug,
          milestoneId: milestone.slug,
          audienceId: milestone.audiences[0].slug,
        }),
      )
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleReSize)
    window.addEventListener('orientationchange', handleReSize)
    return () => {
      window.removeEventListener('resize', handleReSize)
      window.removeEventListener('orientationchange', handleReSize)
    }
  }, [])

  useEffect(() => {
    handleReSize()
  }, [tracks.current_selected_track_id])

  return (
    <Tooltip
      title={milestone?.name}
      disableHoverListener={!showTooltip}
      disableFocusListener={!showTooltip}>
      <div
        ref={buttonRef}
        style={{ width: 'fit-content' }}>
        <AcButton
          label={milestoneLabel || ''}
          tabIndex={isEmptyMilestone ? -1 : 0}
          onClick={handleNavigation}
          className={milestoneClasses}
          style={
            {
              '--track-color': colors.main,
              '--button-height': '56px',
              '--row-gap': '16px',
              '--line-offset': '24px',
              '--button-width': `${buttonWidth}px`,
              '--button-offset-left': `-${offsetLeft}px`,
              '--button-offset-width': `${offsetLeft}px`,
            } as React.CSSProperties
          }
          sx={({ palette }) => ({
            backgroundColor: currentMilestoneActive
              ? colors.light
              : palette.common.white,
            borderColor: `${colors.main} !important`,
            color:
              currentMilestoneActive && !acceptableContrast
                ? palette.common.black
                : colors.main,
            '&:hover': {
              backgroundColor: colors.light,
              color: acceptableContrast ? colors.main : palette.common.black,
              borderColor: `${colors.main} !important`,
              boxShadow: `inset 0px -4px 0px -2px ${colors.main} !important;`,
            },
            pointerEvents: isEmptyMilestone ? 'none' : 'inherit',
          })}
        />
      </div>
    </Tooltip>
  )
}
