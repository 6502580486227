import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { ISearchMeta, ISearchResult } from '@typings'
import { searchParamsToObject } from '@helpers/search-params-to-object'

export class SearchStore {
  store: Store
  open: boolean
  s: string | null
  items: ISearchResult[]
  loading: boolean
  meta: any
  page: number
  activeFilters: { [index: string]: any }

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store

    this.s = null
    this.open = false
    this.loading = false
    this.items = []
    this.meta = null
    this.page = 1
    this.activeFilters = {}
  }

  @computed
  get current_items(): any {
    return this.items
  }

  @computed
  get is_open(): boolean {
    return this.open
  }

  @computed
  get is_loading(): boolean {
    return this.loading
  }

  @computed
  get current_query(): string | null {
    return this.s
  }

  @computed
  get current_page(): number {
    return this.page
  }

  @computed
  get current_query_params(): any {
    return {
      s: this.s,
      page: this.page,
      ...this.activeFilters,
    }
  }

  @action
  close = () => {
    this.store.set(STORE_KEYS.SEARCH, 'open', false)
  }

  @action
  toggle = () => {
    this.store.set(STORE_KEYS.SEARCH, 'open', !this.open)
  }

  @action
  setQuery = (query: string) => {
    this.store.set(STORE_KEYS.SEARCH, 's', query)
  }

  @action
  setPage = (page: number) => {
    this.store.set(STORE_KEYS.SEARCH, 'page', page)
  }

  @action
  setFilter = (key: string, value: string) => {
    // @ts-ignore
    this.activeFilters[key] = value
  }

  @action
  toggleFilter = (key: string, value: string) => {
    this.store.set(STORE_KEYS.SEARCH, 'page', 1)
    if (!this.activeFilters[key]) {
      this.activeFilters[key] = []
    }

    if (this.activeFilters[key].includes(value)) {
      this.activeFilters[key] = this.activeFilters[key].filter(
        (item: string) => item !== value,
      )
      return
    }

    this.activeFilters[key].push(value)
  }

  @action
  search = async () => {
    this.store.set(STORE_KEYS.SEARCH, 'loading', true)
    this.store.set(STORE_KEYS.SEARCH, 'items', [])
    try {
      const results = await this.store.services.search.search(
        this.current_query_params,
      )
      this.store.set(STORE_KEYS.SEARCH, 'items', results.data)
      this.store.set(STORE_KEYS.SEARCH, 'meta', results.meta)
    } catch (error) {
      console.error(error)
    }
    this.store.set(STORE_KEYS.SEARCH, 'loading', false)
  }

  @action
  filterChecked = (key: string, value: string) => {
    return this.activeFilters[key]?.includes(value)
  }

  @action
  parseQueryParams(searchParams: any) {
    const params = searchParamsToObject(searchParams)
    this.setQuery(params?.query)
    this.store.set(STORE_KEYS.SEARCH, 's', params?.s)
    this.store.set(STORE_KEYS.SEARCH, 'page', parseInt(params?.page || '1', 10))
    this.store.set(STORE_KEYS.SEARCH, 'activeFilters', {
      tracks: params?.tracks,
      types: params?.types,
      milestones: params?.milestones,
    })
  }
}
