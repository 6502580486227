import { observer } from 'mobx-react-lite'
import { Box, Container, Link, Typography } from '@mui/material'

import styles from './ac-app-footer.module.scss'
import { AcContainer } from '../../core/ac-container/ac-container'
import { AcTypography } from '../../core/ac-typography/ac-typography'

// @ts-ignore
import logoImage from '../../../assets/visuals/logo-small.svg'
import { useIsMd } from '@hooks/use-isMd'

export const AcAppFooter = observer(() => {
  const isMd = useIsMd()
  return (
    <Box
      component="footer"
      className={styles['ac-app-footer']}>
      <AcContainer maxWidth={992}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3rem 0',
            gap: '1.5rem',
            flexDirection: isMd ? 'row' : 'column',
          }}>
          <Box
            sx={{
              width: '180px',
              flexShrink: 0,
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
            }}>
            <Box
              component="img"
              alt=""
              src={logoImage}
              sx={{
                alignSelf: 'start',
              }}
            />
            <AcTypography
              className={styles['ac-app-footer__title']}
              weight="bold">
              Routekaart Financiële Zorgen
            </AcTypography>
          </Box>
          <Box>
            <AcTypography
              className={styles['ac-app-footer__title']}
              weight="bold">
              Over
            </AcTypography>
            <AcTypography className={styles['ac-app-footer__content']}>
              Dit is een initiatief van de{' '}
              <Link href="https://vng.nl/">VNG</Link> om gemeenten te
              ondersteunen bij de schuldhulpverlening.
            </AcTypography>
          </Box>
          <Box>
            <AcTypography
              className={styles['ac-app-footer__title']}
              weight="bold">
              Contact
            </AcTypography>
            <AcTypography className={styles['ac-app-footer__content']}>
              Heeft u opmerkingen over of suggesties voor de routekaart
              Financiële Zorgen? Onze gegevens vindt u op de pagina{' '}
              <Link href={'/contact'}>contact</Link>. We horen graag van u.
            </AcTypography>
          </Box>
        </Box>
      </AcContainer>
      <Box className={styles['ac-app-footer__linkbar']}>
        <AcContainer>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
              }}>
              <Link
                href="https://vng.nl/privacyverklaring-vereniging-van-nederlandse-gemeenten"
                className={styles['ac-app-footer__linkbar-link']}>
                Privacyverklaring
              </Link>
              <Link
                href="https://vng.nl/proclaimer"
                className={styles['ac-app-footer__linkbar-link']}>
                Proclaimer
              </Link>
            </Box>
            <Box>
              <Typography
                sx={({ palette }) => ({
                  color: palette.primary.main,
                  marginBottom: '0 !important',
                })}>
                VNG &copy; {new Date().getFullYear()}
              </Typography>
            </Box>
          </Box>
        </AcContainer>
      </Box>
    </Box>
  )
})
