import { useMemo } from 'react'
import { Typography, TypographyProps } from '@mui/material'

interface IAcTitle {
  level?: 1 | 2 | 3 | 4
  children: React.ReactNode
  color?: TypographyProps['color']
  className?: string
}

export const AcTitle = ({
  level = 1,
  children,
  className,
  color,
  ...rest
}: IAcTitle) => {
  const variant = useMemo(() => `h${level}`, [])

  return (
    <Typography
      color={color}
      className={className}
      sx={{ wordBreak: 'break-word' }}
      {...rest}
      gutterBottom
      variant={variant as TypographyProps['variant']}>
      {children}
    </Typography>
  )
}
