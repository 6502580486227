import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Box, ButtonBase, Container, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcButton, AcIcon, AcSelect, AcTypography } from '@components'
import { ICONS, KEYS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { usePiwik } from '@hooks/use-piwik'
import {
  ISingleAudienceRelated,
  ISingleMilestone,
  ISingleTrack,
  ISingleTrackMilestone,
} from '@typings'

import styles from './ac-app-header-filters.module.scss'
import { toJS } from 'mobx'

export const AcAppHeaderFilters = observer(() => {
  const { tracks, audiences } = useStore()
  const [milestoneOpen, setMilestoneOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)
  const navigate = useNavigate()
  const isMd = useIsMd()
  const { trackTrackSelect, trackMilestoneSelect } = usePiwik()

  const [related, setRelated] = useState<ISingleAudienceRelated | null>(null)

  const openMilestoneDropdown = () => {
    return setMilestoneOpen(true)
  }

  const closeMilestoneDropdown = () => {
    return setMilestoneOpen(false)
  }

  const toggleFilter = () => {
    setFilterOpen(!filterOpen)
  }

  const currentTrack = useMemo(
    () =>
      tracks.all.find(({ slug }) => slug === tracks.current_selected_track_id),
    [tracks.current_selected_track_id],
  )

  const getCurrentMilestoneOptions = useMemo(() => {
    const arr: ISingleTrackMilestone[] = []
    if (!tracks.current_selected_track_id || !tracks.has_data) return arr

    if (!currentTrack) return arr

    return (
      currentTrack &&
      currentTrack.rows.reduce((bulk, curr) => {
        for (const milestone of curr.milestones) {
          bulk.push(milestone)
        }
        return bulk
      }, arr)
    )
  }, [tracks.current_selected_track_id, tracks.has_data])

  useEffect(() => {
    if (!tracks.current_selected_milestone_id) return
    if (!tracks.current_selected_track_id) return
    if (!getCurrentMilestoneOptions.length) return
    if (!audiences.single) return

    let audienceId =
      audiences.current_id ||
      getCurrentMilestoneOptions.find(
        x => x.slug === tracks.current_selected_milestone_id,
      )?.audiences[0].slug

    if (related) {
      audienceId = related.audience.slug
    }

    navigate(
      generatePath(ROUTE_PATHS.CONTENT_PAGE, {
        trackId: tracks.current_selected_track_id,
        milestoneId: tracks.current_selected_milestone_id,
        audienceId,
      }),
    )
  }, [
    tracks.current_selected_milestone_id,
    audiences.single,
    getCurrentMilestoneOptions.length,
    tracks.current_selected_track_id,
  ])

  useEffect(() => {
    if (tracks.has_data) return
    tracks.getAll()

    return () => {
      tracks.reset()
    }
  }, [])

  const renderFilterDropdowns = useMemo(() => {
    return (
      <>
        <AcSelect
          options={tracks.all}
          selectLabel={LABELS.TRACK}
          key={tracks.current_selected_track_id ?? 'A'}
          value={tracks.current_selected_track_id}
          valueKey={KEYS.SLUG}
          labelKey={KEYS.NAME}
          onChangeCallback={value => {
            openMilestoneDropdown()
            tracks.updateSelectedId(KEYS.TRACKS, value as string)
            trackTrackSelect(tracks.all.find(t => t.id === value))
          }}
        />
        <AcSelect
          open={milestoneOpen}
          onOpen={openMilestoneDropdown}
          onClose={closeMilestoneDropdown}
          options={getCurrentMilestoneOptions}
          selectLabel={LABELS.MILESTONE}
          key={tracks.current_selected_milestone_id ?? 'B'}
          value={tracks.current_selected_milestone_id}
          valueKey={KEYS.SLUG}
          labelKey={KEYS.NAME}
          onChangeCallback={value => {
            tracks.updateSelectedId(KEYS.MILESTONES, value as string)
            // find the audience for the new milestone
            const related = audiences.single?.related.find(
              x => x.milestone.slug === tracks.current_selected_milestone_id,
            )

            if (related) {
              setRelated(related)
            }

            trackMilestoneSelect(
              currentTrack as ISingleTrack,
              getCurrentMilestoneOptions.find(
                ms => ms.slug === value,
              ) as ISingleMilestone,
            )
          }}
        />
      </>
    )
  }, [
    milestoneOpen,
    tracks.all,
    tracks.current_selected_milestone_id,
    tracks.current_selected_track_id,
    getCurrentMilestoneOptions,
  ])

  const renderFilterDropdown = useMemo(() => {
    if (!filterOpen) return null

    return (
      <>
        <Box
          sx={{
            background: 'white',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 12,
            padding: '1rem',
            gap: '1rem',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 0,
          }}>
          {renderFilterDropdowns}

          <AcButton
            onClick={toggleFilter}
            color={'secondary'}
            label={LABELS.START}
          />
          <ButtonBase
            onClick={toggleFilter}
            sx={{
              padding: '0.5rem 1rem 1.25rem 1rem',
            }}>
            <AcTypography
              size="s"
              mb={0}>
              {LABELS.CLOSE}
            </AcTypography>
          </ButtonBase>
        </Box>
        <Box
          sx={{
            opacity: 0.4,
            background: 'var(--ac-palette-primary-main)',
            position: 'absolute',
            inset: 0,
            height: '100dvh',
          }}></Box>
      </>
    )
  }, [
    filterOpen,
    tracks.all,
    milestoneOpen,
    tracks.current_selected_milestone_id,
    tracks.current_selected_track_id,
  ])

  if (isMd) {
    return (
      <Container
        disableGutters={isMd}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: { xs: 2, md: 2 },
          maxWidth: 'unset !important',
        }}>
        {renderFilterDropdowns}
      </Container>
    )
  }

  return (
    <>
      <Container
        disableGutters={isMd}
        sx={{
          padding: '0 !important',
          display: 'flex',
          flexDirection: 'row',
          gap: { xs: 2, md: 2 },
        }}>
        <Box
          sx={{
            border: '1px solid var(--ac-palette-grey-200)',
            borderRadius: '4px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0.75rem 1rem',
            gap: '0.75rem',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}>
            <Box>
              <AcTypography
                className={styles['ac-app-header-filters__title']}
                size="xs"
                weight="bold"
                mb={0}>
                {LABELS.TRACK}
              </AcTypography>
              <AcTypography
                className={styles['ac-app-header-filters__subtitle']}
                size="s"
                mb={0}>
                {
                  tracks.all?.find(
                    x => x.slug === tracks.current_selected_track_id,
                  )?.name
                }
              </AcTypography>
            </Box>

            <Divider
              orientation="vertical"
              sx={{
                height: '37px',
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}>
            <Box>
              <AcTypography
                className={styles['ac-app-header-filters__title']}
                size="xs"
                weight="bold"
                mb={0}>
                {LABELS.MILESTONE}
              </AcTypography>
              <AcTypography
                className={styles['ac-app-header-filters__subtitle']}
                size="s"
                mb={0}>
                {
                  getCurrentMilestoneOptions.find(
                    x => x.slug === tracks.current_selected_milestone_id,
                  )?.name
                }
              </AcTypography>
            </Box>

            <Divider
              orientation="vertical"
              sx={{
                height: '37px',
              }}
            />
          </Box>
          <ButtonBase
            tabIndex={0}
            onClick={toggleFilter}
            sx={{
              color: 'var(--ac-palette-grey-400)',
              display: 'flex',
              alignItems: 'center',
              padding: '0 !important',
            }}>
            <AcIcon icon={ICONS.TUNE} />
          </ButtonBase>
        </Box>
      </Container>
      {renderFilterDropdown}
    </>
  )
})
