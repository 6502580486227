import { observer } from 'mobx-react-lite'
import { ISingleContentBlockDownload } from '@typings'
import styles from '../../features/ac-search-result/ac-search-result.module.scss'
import { Box, Chip, Container, Link } from '@mui/material'
import { AcTypography } from '../ac-typography/ac-typography'
import { AcIcon } from '../ac-icon/ac-icon'
import { ICONS, LABELS } from '@constants'

export const AcDownload = observer(
  ({ description, document, id, uri, name }: ISingleContentBlockDownload) => {
    return (
      <Link
        href={uri}
        className={styles['ac-search-result']}
        sx={({ palette }) => ({
          textDecoration: 'none',
          border: `1px solid ${palette.grey[100]}`,
          '&:hover': {
            borderColor: palette.primary.light,
            borderBottom: `2px solid ${palette.primary.light}`,
            marginBottom: 0,
          },
          color: 'initial',
        })}>
        <Container
          disableGutters
          sx={({ palette }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            color: palette.primary.light,
          })}>
          <AcTypography
            className={styles['ac-search-result__title']}
            size="l"
            weight="bold">
            {name && name}
          </AcTypography>
          <AcIcon
            icon={ICONS.DOWNLOAD}
            color={'primary'}
          />
        </Container>
        <AcTypography>{description}</AcTypography>
      </Link>
    )
  },
)
