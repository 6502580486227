/* eslint-disable no-case-declarations */
import { useCallback, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { ClickAwayListener } from '@mui/base/ClickAwayListener'
import { Card } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcAccordion,
  AcDownload,
  AcEmbed,
  AcImage,
  AcQuote,
  AcRichText,
  ContentPageLinkGrid,
} from '@components'
import matchSubstring from '@helpers/match-substring'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import {
  ISingleContentBlock,
  ISingleContentBlockAccordion,
  ISingleContentBlockCard,
  ISingleContentBlockDownload,
  ISingleContentBlockEmbed,
  ISingleContentBlockImage,
  ISingleContentBlockQuote,
  ISingleContentBlockRichText,
} from '@typings'

import { AcCards } from '../ac-cards/ac-cards'

import classes from './contentpage-content-block.module.scss'
import clsx from 'clsx'

interface IAcContentenpageContentBlock extends ISingleContentBlock {
  home?: boolean
}

export const ConentenpageContentBlock = observer(
  ({ type, data, home = false }: IAcContentenpageContentBlock) => {
    const { terms } = useStore()
    const [showTooltip, setShowTooltip] = useState(false)
    const [tooltipData, setTooltipData] = useState<string>('')
    const [referenceElement, setReferenceElement] =
      useState<HTMLSpanElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null,
    )
    const [arrowElement, setArrowElement] = useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    })
    const isMd = useIsMd()

    const CLASSES = clsx(
      classes['contentpage-content-wrapper'],
      home && classes['contentpage-content-wrapper--home'],
    )

    const handleItemHoverCallback = useCallback(
      (target: any) => {
        setReferenceElement(target)
        const termId = target.getAttribute('data-glossary-uid')
        const term = terms.all_current_terms.find(
          element => element.id == termId,
        )
        if (term) {
          setTooltipData(term.description)
          setShowTooltip(true)
        }
      },
      [referenceElement, showTooltip, terms.all_current_terms],
    )

    const handleItemHoverLeaveCallback = () => {
      setReferenceElement(null)
      setShowTooltip(false)
    }

    const matchGlossaryInContent = useCallback(
      ({ content }: { content: ISingleContentBlockRichText['content'] }) => {
        if (terms.all_current_terms) {
          const collection = [...terms.all_current_terms]
          const len = collection.length
          let n = 0

          for (n; n < len; n++) {
            const item = collection[n]

            const { name, id, description } = item

            // Match glossary items within the content
            const replacer = matchSubstring(name, content, {
              id,
              description,
            })

            if (replacer.match) {
              content = replacer.content
            }
          }
        }

        return content
      },
      [terms.current_page_terms],
    )

    const handleContentEvents = (node: any) => {
      if (!node) return
      let finalLinks: Node[] = []
      const links: NodeList = node.querySelectorAll('mark')

      /* 
       The variable links contain all <mark/> elements within the content-block. 
        Some of these elements might be within a <a/>. 
        We want to prevent those <mark/> elements from showing a tooltip for their
        respective terms for a smoother UX.

        We loop through the links and filter out the ones that have <a/> as parent. We 
        then push those links to the var finalLinks. That is the node[] we use for adding
        the events.
      */

      links.forEach(
        el => el.parentElement?.tagName !== 'A' && finalLinks.push(el),
      )

      if (finalLinks && finalLinks.length > 0) {
        finalLinks.forEach((el: Node) => {
          if (el) {
            if (!isMd) {
              el.addEventListener('click', (e: any) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target) handleItemHoverCallback(e.target)
              })

              return el.removeEventListener('click', (e: any) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target) handleItemHoverCallback(e.target)
              })
            }

            if (isMd) {
              el.addEventListener('mouseenter', (e: any) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target) handleItemHoverCallback(e.target)
              })
              el.addEventListener('mouseleave', e => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target) handleItemHoverLeaveCallback()
              })
              el.addEventListener('keydown', (e: any) => {
                if (e.target && e.key === ' ') {
                  e.preventDefault()
                  e.stopPropagation()
                  handleItemHoverCallback(e.target)
                }
                if (e.target && e.key === 'Escape') {
                  e.preventDefault()
                  e.stopPropagation()
                  handleItemHoverLeaveCallback()
                }
              })
              return el.removeEventListener('mouseenter', (e: any) => {
                e.preventDefault()
                e.stopPropagation()
                if (e.target) handleItemHoverCallback(e.target)
                el.removeEventListener('mouseleave', e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (e.target) handleItemHoverLeaveCallback()
                })
                el.removeEventListener('keydown', (e: any) => {
                  if (e.target && e.key === ' ') {
                    e.preventDefault()
                    e.stopPropagation()
                    handleItemHoverCallback(e.target)
                  }
                  if (e.target && e.key === 'Escape') {
                    e.preventDefault()
                    e.stopPropagation()
                    handleItemHoverLeaveCallback()
                  }
                })
              })
            }
          }
        })
      }
    }

    const renderContent = useMemo(() => {
      switch (type) {
        case 'RichText':
          const content = matchGlossaryInContent(
            data as ISingleContentBlockRichText,
          )

          return (
            <AcRichText
              home={home}
              content={content}
              layout={(data as ISingleContentBlockRichText).layout}
              image={(data as ISingleContentBlockRichText).image}
              embed={(data as ISingleContentBlockRichText).embed}
              ref={handleContentEvents}
            />
          )
        case 'Image':
          return <AcImage {...(data as ISingleContentBlockImage)} />
        case 'Embed':
          return (
            <AcEmbed
              home={home}
              {...(data as ISingleContentBlockEmbed)}
            />
          )
        case 'Quote':
          return <AcQuote {...(data as ISingleContentBlockQuote)} />
        case 'Card':
          return (
            <AcCards
              home={home}
              {...(data as ISingleContentBlockCard)}
            />
          )
        case 'Accordion':
          return (
            <AcAccordion
              home={home}
              {...(data as ISingleContentBlockAccordion)}
            />
          )
        case 'Download':
          return <AcDownload {...(data as ISingleContentBlockDownload)} />
        case 'Index':
          return <ContentPageLinkGrid />
      }
    }, [type])

    return (
      <div className={CLASSES}>
        {renderContent}
        {showTooltip && (
          <ClickAwayListener onClickAway={handleItemHoverLeaveCallback}>
            <Card
              sx={{
                padding: '1rem',
                maxWidth: '250px',
                boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.12)',
                borderRadius: '.25rem',
                position: 'relative',
                zIndex: 5,
              }}
              role="tooltip"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}>
              {tooltipData}
              <div
                ref={setArrowElement as any}
                style={styles.arrow}
              />
            </Card>
          </ClickAwayListener>
        )}
      </div>
    )
  },
)
