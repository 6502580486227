import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Container } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcContentContainer,
  AcGenericViewAsideMenu,
  AcNotFound,
  AcPageWrapper,
  AcTitle,
  ConentenpageContentBlock,
} from '@components'
import { useStore } from '@hooks/use-store'

export const GenericView = observer(() => {
  const location = useLocation()
  const { pages } = useStore()

  useEffect(() => {
    pages.fetchPage(location.pathname)

    return () => {
      pages.reset()
    }
  }, [location.pathname])

  const generateAsideMenuItems = useMemo(() => {
    if (!pages.single) return []

    if (pages.single.parent) {
      return [pages.single.parent, ...pages.single.siblings]
    }

    return [pages.single, ...pages.single.children]
  }, [pages.single])

  return (
    <AcPageWrapper>
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          flex: '1 0 auto',
          gap: '2.5rem',
          alignItems: 'stretch',
          padding: '3rem 0',
        }}>
        {(pages.single?.siblings || !!pages.single?.children.length) && (
          <AcGenericViewAsideMenu items={generateAsideMenuItems} />
        )}
        <AcContentContainer loading={pages.loading}>
          {!pages.single && <AcNotFound />}
          {pages.single?.name && (
            <AcTitle
              level={1}
              color={'primary'}>
              {pages.single?.name}
            </AcTitle>
          )}
          {pages.single?.blocks &&
            pages.single.blocks.map((singleBlock: any) => (
              <ConentenpageContentBlock
                key={singleBlock.id}
                {...singleBlock}
              />
            ))}
        </AcContentContainer>
      </Container>
    </AcPageWrapper>
  )
})
