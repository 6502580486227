import { Outlet } from 'react-router-dom'

import {
  AcAppFooter,
  AcAppHeader,
  AcAppNavigation,
  AcDrawer,
  AcScrollTop,
  AcSearchDrawer,
} from '@components'

export const AcDefaultLayout = (): JSX.Element => {
  return (
    <>
      <AcScrollTop />
      <AcAppNavigation />
      <AcAppHeader />
      <Outlet />
      <AcAppFooter />
      <AcDrawer withToolbar />
      <AcSearchDrawer />
    </>
  )
}
