import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { IMenuItem, ISingleMilestone, ISingleMilestoneAudience } from '@typings'

export class NavigationStore {
  store: Store
  open: boolean
  searchVisible: boolean
  menuItems: IMenuItem[]

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store

    this.menuItems = []
    this.open = false
    this.searchVisible = false
  }

  @computed
  get is_open(): boolean {
    return this.open
  }

  @computed
  get current_menu_items(): IMenuItem[] {
    return this.menuItems
  }

  @action
  toggle = () => {
    this.store.set(STORE_KEYS.NAVIGATION, 'open', !this.open)
  }

  @action
  toggleSearchActive = () => {
    this.store.set(STORE_KEYS.NAVIGATION, 'searchVisible', !this.searchVisible)
  }

  @action
  setMenuItems = (menuItems: IMenuItem[]) => {
    this.menuItems = menuItems
  }

  @action
  fetchMenuItems = async () => {
    try {
      const menuItems = await this.store.services.menus.getAll()
      this.setMenuItems(menuItems)
    } catch (error) {
      console.error(error)
    }
  }
}
