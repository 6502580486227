import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcEmbed, AcImage, AcTitle, AcTypography } from '@components'
import { useIsMd } from '@hooks/use-isMd'

// @ts-ignore
import DemoImage from '../../../assets/visuals/demo-image.png'
import { ISingleContentBlockEmbed, ISingleContentBlockImage } from '@typings'

interface IACImageText {
  reverse?: boolean
  content: any
  image?: ISingleContentBlockImage
  embed?: ISingleContentBlockEmbed
}

export const AcRichTextLeftRight = observer(
  ({ content, image, embed, reverse = false }: IACImageText) => {
    const isMd = useIsMd()
    return (
      <Box
        sx={{
          display: {
            md: 'grid',
            xs: 'flex',
          },
          gridTemplateColumns: '1fr 1fr',
          gridGap: isMd ? '5rem' : '1.5rem',
          flexDirection: 'column',
        }}>
        <Box
          sx={{
            order: reverse && isMd ? 2 : 1,
          }}>
          {image && <AcImage {...image} />}
          {embed && <AcEmbed {...embed} />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            order: reverse && isMd ? 1 : 2,
          }}>
          <Box
            sx={{
              'h1, h2, h3, h4': {
                color: 'var(--ac-palette-primary-main)',
              },
            }}>
            <AcTypography html>{content}</AcTypography>
          </Box>
        </Box>
      </Box>
    )
  },
)
