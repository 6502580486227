import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISearchResponse, ISearchResult, ISingleMilestone } from '@typings'

export class SearchService extends AxiosBaseApi {
  search = (params: any = {}) =>
    this.api
      .get<AxiosResponse<ISearchResponse>>(ENDPOINTS.SEARCH.ALL(), {
        params,
      })
      .then(response => response.data)
}
