import { ThemeOptions } from '@mui/material'

export const PALETTE_LIGHT: ThemeOptions['palette'] = {
  mode: 'light',
  primary: {
    '100': '#E5F4FD',
    '200': '#B2E8FF',
    '300': '#009FE4',
    '400': '#004488',
    main: '#1A237E',
    contrastText: '#fff',
  },
  secondary: {
    main: '#009FE4',
    contrastText: '#1A237E',
  },
  common: {
    black: '#1D282C',
    white: '#fff',
  },
  text: {
    primary: '#1D282C',
    secondary: '#B0B8C4',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  grey: {
    '100': '#E6E6E7',
    '200': '#CCCCCE',
    '300': '#929296',
    '400': '#4D4D54',
    '500': '#01010B',
  },
  error: {
    main: '#F41B1B',
    contrastText: '#fff',
  },
  success: {
    main: '#00B74A',
    contrastText: '#fff',
  },
  warning: {
    main: '#F4801B',
    contrastText: '#fff',
  },
  info: {
    main: '#B2E8FF',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
}
