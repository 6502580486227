import { useEffect, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Toolbar,
} from '@mui/material'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'

import { AcAppNavigationDropdown, AcDrawerContact, AcIcon } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsLg } from '@hooks/use-isLg'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { IMenuItem } from '@typings'

// @ts-ignore
import logoImage from '../../../assets/visuals/logo.svg'
// @ts-ignore
import logoSmallImage from '../../../assets/visuals/logo-small.svg'

import styles from './ac-app-navigation.module.scss'

export const AcAppNavigation = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const { navigation, search, drawer } = useStore()
  const isMd = useIsMd()
  const isLg = useIsLg()

  useEffect(() => {
    navigation.fetchMenuItems()
  }, [])

  const handleSearch = (value: string) => {
    search.setQuery(value)
  }

  const navigateToSearch = (e: React.FormEvent) => {
    e.preventDefault()
    if (search.current_query) {
      navigate(`${ROUTE_PATHS.SEARCH}?s=${search.current_query}`)
    }
  }

  const renderSearchBox = useMemo(() => {
    if (location.pathname === ROUTE_PATHS.SEARCH) {
      return null
    }

    return (
      <Box
        sx={{
          flexGrow: 1,
        }}
        component="form"
        onSubmit={e => navigateToSearch(e)}>
        <TextField
          className={styles['search-box']}
          size="small"
          fullWidth
          sx={{ mt: 2 }}
          placeholder={LABELS.SEARCH}
          onChange={e => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AcIcon icon={ICONS.SEARCH} />
              </InputAdornment>
            ),
            endAdornment: search.current_query && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => handleSearch('')}>
                  <AcIcon icon={ICONS.CLOSE} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }, [location, search.current_query])

  const renderSmall = useMemo(() => {
    if (isMd) {
      return
    }

    return (
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem',
        }}>
        {!navigation.searchVisible && (
          <Link
            href={'/'}
            sx={() => ({
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              margin: 0,
            })}>
            <Box
              component="img"
              alt=""
              src={logoSmallImage}
            />
          </Link>
        )}

        {navigation.searchVisible && renderSearchBox}
        <Box>
          <IconButton
            onClick={() => navigation.toggleSearchActive()}
            sx={({ palette }) => ({
              color: palette.primary.contrastText,
            })}>
            <AcIcon
              icon={navigation.searchVisible ? ICONS.CLOSE : ICONS.SEARCH}
            />
          </IconButton>
          {!navigation.searchVisible && (
            <IconButton
              onClick={() => navigation.toggle()}
              sx={({ palette }) => ({
                color: palette.primary.contrastText,
              })}>
              <AcIcon icon={navigation.is_open ? ICONS.CLOSE : ICONS.MENU} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    )
  }, [
    isMd,
    navigation.is_open,
    navigation.searchVisible,
    navigation.current_menu_items,
    location.pathname,
  ])

  const renderMedium = useMemo(() => {
    if (!isMd) {
      return
    }
    return (
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            gap: '2rem',
            height: '4rem',
          }}>
          <Link
            href={'/'}
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: 'center',
              margin: 0,
            }}>
            <Box
              component="img"
              alt=""
              src={isLg ? logoImage : logoSmallImage}
            />
          </Link>
          <Divider
            orientation="vertical"
            flexItem
            className={styles['divider']}
          />
          {navigation.current_menu_items.map((menu: IMenuItem) => {
            return (
              <NavLink
                className={clsx(
                  styles['nav-link'],
                  location.pathname === `/${menu.slug}` &&
                    styles['nav-link__active'],
                )}
                to={`/${menu.slug}`}>
                {menu.name}
              </NavLink>
            )
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            gap: '2rem',
          }}>
          {renderSearchBox}
          <Box
            tabIndex={0}
            role="button"
            className={clsx(styles['nav-link'])}
            onClick={() =>
              drawer.toggleDrawer(LABELS.CONTACT, <AcDrawerContact />)
            }>
            Contact
          </Box>
        </Box>
      </Toolbar>
    )
  }, [navigate, isMd, navigation.current_menu_items, location.pathname, isLg])

  return (
    <AppBar
      id="navbar"
      component="nav"
      position="sticky"
      sx={({ palette }) => ({
        background: palette.primary.main,
        color: palette.primary.contrastText,
        alignContent: 'center',
        zIndex: theme => theme.zIndex.drawer + 2,
        boxShadow: 'none',
      })}>
      {renderMedium}
      {renderSmall}
      <AcAppNavigationDropdown />
    </AppBar>
  )
})
