import type { ThemeOptions } from '@mui/material'
import { alpha } from '@mui/material/styles'
import type {
  ColorPartial,
  PaletteColor,
} from '@mui/material/styles/createPalette'

import AvenirBold from '../../assets/fonts/Avenir-Heavy.woff2'
import AvenirRoman from '../../assets/fonts/Avenir-Roman.woff2'

// Fonts
import { PALETTE_LIGHT } from './palette.theme'
import { FONT_FAMILIES, FONT_WEIGHTS } from './typography.theme'

const colors = {
  ...PALETTE_LIGHT,
  primary: PALETTE_LIGHT?.primary as ColorPartial,
  success: PALETTE_LIGHT?.success as PaletteColor,
  warning: PALETTE_LIGHT?.warning as PaletteColor,
  error: PALETTE_LIGHT?.error as PaletteColor,
  grey: PALETTE_LIGHT?.grey as ColorPartial,
}

export const COMPONENTS: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'VNG-Bold';
        font-style: normal;
        font-display: swap;
        font-weight: 800;
        src: local('VNG-Bold'), url(${AvenirBold}) format('woff2');
      }
      @font-face {
        font-family: 'VNG-Roman';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('VNG-Roman'), url(${AvenirRoman}) format('woff2');
      }
      
      h1, h2, h3, h4, h5, h6 {
        font-family: ${FONT_FAMILIES.HEADING};
        font-weight: ${FONT_WEIGHTS.BOLD};
      }
      
      h1 {
        font-size: 1rem;
        line-height: 1.2;
        
        @media (min-width:768px) {
          font-size: 2.25rem;
        }
      }
      
      h2 {
        font-size: 1.5rem;
        line-height: 1.2;
        @media (min-width:768px) {
          font-size: 1.75rem;
        }
      }
      
      h3 {
        font-size: 1.25rem;
        line-height: 1.2;
        
        @media (min-width:768px) {
          font-size: 1.5rem;
        }
      }
      
    `,
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: 16,
        ':focus-visible': {
          border: '1px solid',
          borderColor: colors.common?.white,
          outline: '3px solid',
          outlineColor: colors.common?.black,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        padding: 16,
        minWidth: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.primary['300'],
        },
        ':disabled': {
          backgroundColor: alpha(colors.primary['400'] as string, 0.7),
          color: colors.common?.white,
        },
      },
      text: {
        padding: 4,
        margin: 12,
        fontWeight: 400,
        '&:focus': {
          border: 'none',
        },
        '&:focus-visible': {
          border: 'none',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        width: 'fit-content',
        border: '2px solid',
      },
      standardSuccess: {
        borderColor: colors.success?.main,
        color: colors.success?.main,
      },
      standardWarning: {
        borderColor: colors.warning?.main,
        color: colors.warning?.main,
      },
      standardError: {
        borderColor: colors.error?.main,
        color: colors.error?.main,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        marginBottom: '0.45em',
      },
      h1: {
        marginBottom: '1rem',
      },
      h2: {
        marginBottom: '1rem',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '.MuiFormControlLabel-label': {
          margin: 0,
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: colors.primary[300],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        paddingBlock: 8,
        paddingInline: 12,
        '&.Mui-selected': {
          color: colors.primary[300],
        },
        ':focus-visible': {
          border: '2px dashed',
          borderColor: colors.primary[500],
          outline: 'none',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: 80,
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        color: colors.grey[400],
        backgroundColor: colors.common?.white,
        boxShadow: '0px 4px 13px 0px rgba(0, 0, 0, 0.12)',
        padding: 16,
        fontSize: 14,
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: colors.primary['100'],
        padding: '0 0.75rem',

        '.MuiChip-label': {
          padding: '0',
          paddingLeft: '0.25rem',
        },
      },
    },
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        background: 'transparent',
        fontWeight: 'normal',
        color: colors?.primary['300'],
        paddingTop: '3px',
        '&.Mui-selected': {
          background: 'transparent !important',
          border: `1px solid ${colors.primary['300']}`,
          borderRadius: '4px',
          color: colors?.text?.primary,
          fontWeight: 'bold',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        transform: 'scale(0.9)',
        '&.Mui-focusVisible': {
          outline: `3px solid ${colors.primary['300']}`,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        ':hover': {
          color: colors.common?.black,
        },
        ':focus-visible': {
          border: '1px solid',
          borderColor: colors.common?.white,
          outline: '3px solid',
          borderRadius: '4px',
          outlineColor: colors.common?.black,
          color: colors.common?.black,
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        margin: 0,
        boxShadow: 'none',
        '.MuiAccordionSummary-root': {
          flexDirection: 'row-reverse',
          gap: '1rem',
          padding: '1.5rem 0',
          '.MuiAccordionSummary-content': {
            margin: 0,
            p: {
              fontSize: '20px !important',
            },
          },
          '.MuiAccordionDetails-root': {
            textAlign: 'left !important',
          },
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.Mui-focused, &:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: ' 2px !important',
            borderStyle: 'solid !important',
            borderColor: 'var(--ac-palette-warning-main) !important',
            boxSizing: 'border-box !important',
          },
        },
      },
    },
  },
}
