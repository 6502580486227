import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISingleMilestone } from '@typings'

export class MilestonesService extends AxiosBaseApi {
  getSingle = (slug: ISingleMilestone['slug']) =>
    this.api
      .get<AxiosResponse<{ data: ISingleMilestone }>>(
        ENDPOINTS.MILESTONES.SINGLE(slug),
      )
      .then(response => response.data.data)
}
