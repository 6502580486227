import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  Skeleton,
} from '@mui/material'
import { AcTypography } from '@components'
import styles from './ac-search-filters.module.scss'
import { useMemo } from 'react'
import { useIsMd } from '@hooks/use-isMd'
import CloseIcon from '@mui/icons-material/Close'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { IFilterItem } from '@typings'

export const AcSearchFilters = observer(() => {
  const { search } = useStore()

  const isMd = useIsMd()
  const renderHeading = useMemo(() => {
    if (!isMd) {
      return (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1rem',
            }}>
            <AcTypography
              className={styles['ac-search-filters__title']}
              size="l"
              mb={0}
              weight="bold">
              Filters
            </AcTypography>
            <CloseIcon
              onClick={() => search.close()}
              className={styles['close-button']}
              fontSize="small"
            />
          </Box>
          <Divider />
        </Box>
      )
    }

    return (
      <AcTypography
        className={styles['ac-search-filters__title']}
        size="l"
        weight="bold">
        Filters
      </AcTypography>
    )
  }, [isMd])

  const renderCheckBoxSkeletons = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: '0.75rem',
          flexDirection: 'column',
        }}>
        {Array.from({ length: 5 }).map(_ => (
          <Skeleton
            variant="rectangular"
            height={30}
          />
        ))}
      </Box>
    )
  }, [])

  const renderTypeFilters = useMemo(() => {
    if (!search.meta) return renderCheckBoxSkeletons
    return search.meta?.filters?.types.map((type: IFilterItem) => (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={search.filterChecked(
              'types',
              type.value.toString(),
            )}
            onChange={() => toggleFilter('types', type.value.toString())}
          />
        }
        label={type.label}
      />
    ))
  }, [search.meta])

  const renderTrackFilters = useMemo(() => {
    if (!search.meta) return renderCheckBoxSkeletons
    return search.meta?.filters?.tracks.map((track: IFilterItem) => (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={search.filterChecked(
              'tracks',
              track.value.toString(),
            )}
            onChange={() => toggleFilter('tracks', track.value.toString())}
          />
        }
        label={track.label}
      />
    ))
  }, [search.meta])

  const renderMilestoneFilters = useMemo(() => {
    if (!search.meta) return renderCheckBoxSkeletons
    return search.meta?.filters?.milestones.map((milestone: IFilterItem) => (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={search.filterChecked(
              'milestones',
              milestone.value.toString(),
            )}
            onChange={() =>
              toggleFilter('milestones', milestone.value.toString())
            }
          />
        }
        label={milestone.label}
      />
    ))
  }, [search.meta])

  const toggleFilter = async (key: string, value: string) => {
    search.toggleFilter(key, value)
    await search.search()
  }

  return (
    <List
      className={styles['ac-search-filters']}
      role={'menu'}
      sx={{ minWidth: 244, paddingTop: 0 }}>
      {renderHeading}
      <Box
        sx={{
          padding: !isMd ? '1rem' : 0,
          paddingTop: 0,
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
        }}>
        <FormGroup>
          <AcTypography
            className={styles['ac-search-filters__form-group-title']}
            size="l"
            weight="bold">
            Soort
          </AcTypography>
          {renderTypeFilters}
        </FormGroup>
        <FormGroup>
          <AcTypography
            className={styles['ac-search-filters__form-group-title']}
            size="l"
            weight="bold">
            Spoor
          </AcTypography>
          {renderTrackFilters}
        </FormGroup>
        <FormGroup>
          <AcTypography
            className={styles['ac-search-filters__form-group-title']}
            size="l"
            weight="bold">
            Mijlpaal
          </AcTypography>
          {renderMilestoneFilters}
        </FormGroup>
      </Box>
    </List>
  )
})
