import { Outlet } from 'react-router-dom'

import {
  AcAppFooter,
  AcAppNavigation,
  AcAppNavigationDropdown,
  AcDrawer,
  AcScrollTop,
  AcSearchDrawer,
} from '@components'

export const AcNoHeaderLayout = () => {
  return (
    <>
      <AcScrollTop />
      <AcAppNavigation />

      <Outlet />

      <AcAppFooter />
      <AcDrawer />
      <AcSearchDrawer />
    </>
  )
}
