import { useEffect } from 'react'
import { Container } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcSelect } from '@components'
import { KEYS, LABELS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { usePiwik } from '@hooks/use-piwik'
import { useStore } from '@hooks/use-store'

export const AcAppHeaderTrackSelect = observer(() => {
  const { tracks } = useStore()
  const isMd = useIsMd()
  const { trackTrackSelect } = usePiwik()

  useEffect(() => {
    if (tracks.has_data) return
    tracks.getAll()
  }, [])

  return (
    <Container
      disableGutters={isMd}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 2, md: 0 },
      }}>
      <AcSelect
        options={tracks.all}
        withEmpty
        emptyLabel={LABELS.CLEAR_TRACK_SELECTION}
        selectLabel={LABELS.TRACK}
        key={tracks.current_selected_track_id ?? 'A'}
        value={tracks.current_selected_track_id}
        valueKey={KEYS.SLUG}
        labelKey={KEYS.NAME}
        onChangeCallback={value => {
          tracks.updateSelectedId(KEYS.TRACKS, value as string)
          trackTrackSelect(tracks.all.find(t => t.id === value))
        }}
      />
    </Container>
  )
})
