import { ISingleContentBlockImage } from '@typings'

import styles from './ac-image.module.scss'

export const AcImage = ({
  url,
  srcset,
  file_name,
}: ISingleContentBlockImage) => {
  if (!url) return

  /*
    NOTE

    srcset has been disabled due to issue in AS-14027
    where smaller images are being forced to 100% due to the srcset
    This needs a permanent fix (probably in the API => Spatie medialibrary)
    but for now, this will do

    here's the code for future references:
      srcSet={srcset}

    and here's a banana, bon apetit!

     _
    //\
    V  \
     \  \_
      \,'.`-.
       |\ `. `.       
       ( \  `. `-.                        _,.-:\
        \ \   `.  `-._             __..--' ,-';/
         \ `.   `-.   `-..___..---'   _.--' ,'/
          `. `.    `-._        __..--'    ,' /
            `. `-_     ``--..''       _.-' ,'
              `-_ `-.___        __,--'   ,'
                 `-.__  `----"""    __.-'
                      `--..____..--'

  */

  return (
    <img
      style={{
        borderRadius: '4px',
      }}
      className={styles['ac-image']}
      src={url}
      alt={file_name}
    />
  )
}
