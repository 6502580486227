import { Button, ButtonProps } from '@mui/material'

interface IAcButton extends ButtonProps {
  label: string
  variant?: 'text' | 'contained' | 'outlined'
  color?: 'primary' | 'success' | 'warning' | 'error' | 'secondary'
  icon?: React.ReactNode
}

export const AcButton = ({
  variant = 'contained',
  color = 'primary',
  label,
  ...rest
}: IAcButton) => {
  return (
    <Button
      {...rest}
      color={color}
      variant={variant}>
      {label}
    </Button>
  )
}
