import { createContext } from 'react'
import { action, makeAutoObservable } from 'mobx'

import { saveToStorage } from '@helpers/browser-storage'
import Services from '@services'

import { AudiencesStore } from './audiences.stores'
import { DrawerStore } from './drawer.stores'
import { MilestonesStore } from './milestone.stores'
import { TracksStore } from './tracks.stores'
import { TermsStore } from './terms.stores'
import { NavigationStore } from './navigation.stores'
import { SearchStore } from './search.stores'
import { PagesStore } from './pages.stores'

export class Store {
  services: ReturnType<typeof Services>
  tracks: TracksStore
  audiences: AudiencesStore
  milestones: MilestonesStore
  drawer: DrawerStore
  terms: TermsStore
  navigation: NavigationStore
  search: SearchStore
  pages: PagesStore

  constructor() {
    makeAutoObservable(this)
    this.services = Services()
    this.tracks = new TracksStore(this)
    this.audiences = new AudiencesStore(this)
    this.milestones = new MilestonesStore(this)
    this.drawer = new DrawerStore(this)
    this.terms = new TermsStore(this)
    this.navigation = new NavigationStore(this)
    this.search = new SearchStore(this)
    this.pages = new PagesStore(this)
  }

  @action
  set<S extends keyof Store, K extends keyof Store[S], A extends Store[S][K]>(
    store: S,
    key: K,
    value: A,
    save?: boolean,
  ) {
    if (!key || !store) return
    ;(this as Store)[store][key] = value
    if (save) saveToStorage(key as string, value)
  }
}

export const StoreContext = createContext<Store>(new Store())
