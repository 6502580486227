import { action, computed, makeAutoObservable } from 'mobx'

import { Store } from '@stores'
import { IPage } from '@typings'
import { STORE_KEYS } from '@constants'

export class PagesStore {
  store: Store
  items: IPage[]
  loading: boolean
  single: IPage | null
  contact: IPage | null
  contactLoading: boolean
  parent: IPage | null
  parentLoading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store

    this.loading = false
    this.items = []
    this.single = null
    this.contact = null
    this.contactLoading = false
    this.parent = null
    this.parentLoading = false
  }

  @computed
  get current_items(): any {
    return this.items
  }

  @computed
  get is_loading(): boolean {
    return this.loading
  }

  @action
  fetchPages = async () => {
    this.store.set(STORE_KEYS.PAGES, 'loading', true)
    try {
      const pages = await this.store.services.pages.getAll()
      this.store.set(STORE_KEYS.PAGES, 'items', pages)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.PAGES, 'loading', false)
    }
  }

  @action
  fetchPage = async (slug: string) => {
    this.store.set(STORE_KEYS.PAGES, 'loading', true)
    try {
      const page = await this.store.services.pages.getSingle(slug)
      this.store.set(STORE_KEYS.PAGES, 'single', page)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.PAGES, 'loading', false)
    }
  }

  @action
  fetchParent = async (slug: string) => {
    this.store.set(STORE_KEYS.PAGES, 'parentLoading', true)
    try {
      const page = await this.store.services.pages.getSingle(slug)
      this.store.set(STORE_KEYS.PAGES, 'parent', page)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.PAGES, 'parentLoading', false)
    }
  }

  @action
  fetchContact = async () => {
    this.store.set(STORE_KEYS.PAGES, 'contactLoading', true)
    try {
      const page = await this.store.services.pages.getSingle('contact')
      this.store.set(STORE_KEYS.PAGES, 'contact', page)
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.PAGES, 'contactLoading', false)
    }
  }

  @action
  reset = () => {
    this.store.set(STORE_KEYS.PAGES, 'items', [])
    this.store.set(STORE_KEYS.PAGES, 'single', null)
    this.store.set(STORE_KEYS.PAGES, 'parent', null)
    this.store.set(STORE_KEYS.PAGES, 'loading', false)
  }
}
