import { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  Toolbar,
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcHeaderButton } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { usePiwik } from '@hooks/use-piwik'
import { useStore } from '@hooks/use-store'

import {
  AcAppHeaderFilters,
  AcAppHeaderTrackSelect,
  AcDrawerContact,
  AcDrawerTerms,
} from '../index.features.components'

import styles from './ac-app-header.module.scss'

export const AcAppHeader = observer(() => {
  const navigate = useNavigate()
  const { trackContactDrawer, trackTermsDrawer } = usePiwik()
  const { pathname } = useLocation()
  const [showSelectable, setShowSelectable] = useState(false)
  const { drawer, tracks } = useStore()
  const isMd = useIsMd()

  const showTrackFilter = useMemo(() => {
    return pathname === ROUTE_PATHS.TRACKS
  }, [pathname])

  const showTermList = useMemo(
    () => pathname !== ROUTE_PATHS.HOME && pathname !== ROUTE_PATHS.TRACKS,
    [pathname],
  )

  const handleDrawerClick = () => {
    if (showSelectable) setShowSelectable(false)
    drawer.toggleDrawer(LABELS.TERMSLIST, <AcDrawerTerms />)
    trackTermsDrawer()
  }

  const getSelectedTracks = useMemo(() => {
    if (showTrackFilter) {
      return tracks.current_selected_track_id !== null ? '1' : null
    }
    const getNumber =
      tracks.current_selected_track_id && tracks.current_selected_milestone_id
        ? '2'
        : '1'
    const match = matchPath(ROUTE_PATHS.CONTENT_PAGE, pathname)
    return match !== null ? getNumber : null
  }, [
    pathname,
    showTrackFilter,
    tracks.current_selected_milestone_id,
    tracks.current_selected_track_id,
  ])

  const handleContactClick = () => {
    if (showSelectable) setShowSelectable(false)
    drawer.toggleDrawer(LABELS.CONTACT, <AcDrawerContact />)
    trackContactDrawer()
  }

  const handleShowSelectable = useCallback(() => {
    if (showSelectable) setShowSelectable(false)
  }, [showSelectable])

  useEffect(() => {
    handleShowSelectable()
    return () => {
      if (drawer.open) {
        drawer.close()
      }
    }
  }, [pathname])

  const renderMd = useMemo(() => {
    return (
      <Toolbar
        sx={{
          minHeight: '88px !important',
          gap: '1rem',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            flexGrow: 1,
          }}>
          {showTrackFilter ? (
            <>
              <Box sx={{ marginRight: { xs: 0, md: 1.5 } }}>
                <AcHeaderButton
                  icon={ICONS.ARROW_BACK}
                  label={LABELS.BACK}
                  onClick={() => navigate(-1)}
                />
              </Box>
              <AcAppHeaderTrackSelect />
            </>
          ) : (
            <AcAppHeaderFilters />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: '1 0 auto',
            flexGrow: 0,
          }}>
          {showTermList && (
            <AcHeaderButton
              icon={ICONS.LIST}
              label={LABELS.TERMSLIST}
              onClick={handleDrawerClick}
              active={drawer.get_title === LABELS.TERMSLIST}
            />
          )}
          <NavLink
            title={LABELS.ROADMAP}
            className={styles['button-link']}
            to={ROUTE_PATHS.TRACKS}>
            {({ isActive }) => (
              <AcHeaderButton
                icon={ICONS.ROADMAP}
                label={LABELS.ROADMAP}
                active={isActive}
                isLink
              />
            )}
          </NavLink>
        </Box>
      </Toolbar>
    )
  }, [drawer.get_title, getSelectedTracks, showSelectable, showTrackFilter])

  const renderNotMd = useMemo(() => {
    return (
      <ClickAwayListener onClickAway={handleShowSelectable}>
        <Toolbar
          role="presentation"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Container
            disableGutters
            sx={{ flex: 1, width: '100%', marginTop: '1rem' }}>
            <Box
              sx={({ palette }) => ({
                color: palette.grey['400'],
              })}>
              {showTrackFilter ? (
                <AcAppHeaderTrackSelect />
              ) : (
                <AcAppHeaderFilters />
              )}
            </Box>
          </Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}>
            <NavLink
              title={LABELS.ROADMAP}
              className={styles['button-link']}
              to={ROUTE_PATHS.TRACKS}>
              {({ isActive }) => (
                <AcHeaderButton
                  icon={ICONS.ROADMAP}
                  label={LABELS.ROADMAP}
                  active={isActive}
                  isLink
                />
              )}
            </NavLink>
            {showTermList && (
              <AcHeaderButton
                icon={ICONS.LIST}
                label={LABELS.TERMSLIST}
                active={drawer.get_title === LABELS.TERMSLIST}
                onClick={handleDrawerClick}
              />
            )}
          </Box>
        </Toolbar>
      </ClickAwayListener>
    )
  }, [drawer.get_title, getSelectedTracks, showSelectable, showTrackFilter])

  return (
    <AppBar
      id="navbar"
      component={'nav'}
      position="sticky"
      sx={({ palette }) => ({
        background: palette.common.white,
        alignContent: 'center',
        top: '64px',
        zIndex: theme => theme.zIndex.drawer + 1,
      })}>
      {isMd && renderMd}
      {!isMd && renderNotMd}
    </AppBar>
  )
})
