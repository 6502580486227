import { forwardRef, useMemo } from 'react'

import { ISingleContentBlockRichText } from '@typings'

import styles from './ac-richtext.module.scss'
import { Box } from '@mui/material'
import { AcEmbed } from '../ac-embed/ac-embed'
import { AcTitle } from '../ac-title/ac-title'
import { useIsMd } from '@hooks/use-isMd'
import { AcRichTextLeftRight } from '../../features/ac-rich-text-left-right/ac-rich-text-left-right'
import { AcContainer } from '../ac-container/ac-container'

interface IAcRichText extends ISingleContentBlockRichText {
  home?: boolean
}

export const AcRichText = forwardRef(
  (
    { content, layout, image, embed, home = false }: IAcRichText,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    if (!content) return null

    const renderContent = useMemo(() => {
      switch (layout) {
        case 'right-image':
          return (
            <AcRichTextLeftRight
              content={content}
              image={image}
              reverse
            />
          )

        case 'left-image':
          return (
            <AcRichTextLeftRight
              content={content}
              image={image}
            />
          )

        case 'right-embed':
          return (
            <AcRichTextLeftRight
              content={content}
              embed={embed}
              reverse
            />
          )

        case 'left-embed':
          return (
            <AcRichTextLeftRight
              content={content}
              embed={embed}
            />
          )

        default:
          return (
            <article
              ref={ref}
              className={styles['ac-richtext']}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )
      }
    }, [layout, content, image])

    if (home) {
      return (
        <Box
          sx={{
            paddingBlock: '4rem',
          }}>
          <AcContainer maxWidth={1152}>{renderContent}</AcContainer>
        </Box>
      )
    }

    return renderContent
  },
)
