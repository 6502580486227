import { forwardRef } from 'react'
import { Button, ButtonProps } from '@mui/material'

import { ICONS } from '@constants'

import { AcIcon } from '../index.core.components'

import styles from './ac-header-button.module.scss'

interface IAcHeaderButton extends ButtonProps {
  icon: keyof typeof ICONS
  label?: string
  active?: boolean
  isLink?: boolean
  sx?: ButtonProps['sx']
}

export const AcHeaderButton = forwardRef<HTMLButtonElement, IAcHeaderButton>(
  ({ icon, label, active, isLink = false, sx, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        variant="text"
        sx={theme => ({
          minWidth: 0,
          margin: isLink ? 0 : 1,
          color: active
            ? theme.palette.primary.light
            : theme.palette.common.black,
          '&:hover': {
            color: theme.palette.primary.light,
            fill: theme.palette.primary.light,
            background: 'none',
          },
          sx,
        })}
        {...rest}
        aria-label={label}
        className={styles['ac-header-button']}
        tabIndex={isLink ? -1 : 0}>
        <div className={styles['ac-header-button__wrapper']}>
          {icon && (
            <AcIcon
              icon={icon}
              size={'sm'}
            />
          )}
          <span className={styles['ac-header-button__label']}>{label}</span>
        </div>
      </Button>
    )
  },
)
