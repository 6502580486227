export const LABELS = {
  CONTACT: 'Contact',
  HOME: 'Home',
  BACK: 'Terug',
  MILESTONE: 'Mijlpaal',
  MORE_INFO: 'Meer informatie',
  NONE: '',
  ROADMAP: 'Routekaart',
  TERMSLIST: 'Begrippenlijst',
  NO_RESULTS: 'Geen resultaten gevonden',
  NO_DATA: 'Er is geen data beschikbaar voor dit onderdeel',
  TRACK: 'Spoor',
  SEARCH: 'Zoeken',
  SEARCH_PLACEHOLDER: 'Zoeken op term, onderwerp of begrip',
  TERMS: 'Begrippenlijst',
  TERMS_GENERAL: 'Algemeen',
  PAGE_SPECIFIC: 'Pagina specifiek',
  TERMS_ERROR:
    'We konden helaas geen begrippen vinden. Probeer het later nog een keer',
  OPEN: 'Openen',
  CLOSE: 'Sluiten',
  VIEW_NEXT_TRACK: 'Volgende spoor bekijken',
  VIEW_PREV_TRACK: 'Vorige spoor bekijken',
  CLEAR_TRACK_SELECTION: 'Alle sporen',
  MORE_TRACKS: 'Meer sporen',
  TABLE_OF_CONTENTS: 'Inhoudsopgave',
  FILTERS: 'Toon filter(s)',
  YOUR_FILTER: 'Uw filter',
  YOU_ARE_HERE: 'U bevind zich',
  WORKING_ON_NEW_CONTENT: 'Het komende jaar worden er meer sporen gepubliceerd',
  A11Y_SWIPER_CONTAINER_MESSAGE: 'Routekaart. Aantal sporen zichtbaar:',
  A11Y_SWIPER_LAST_SLIDE_MESSAGE: 'U bevindt zicht nu op het laatste spoor',
  A11Y_SWIPER_SLIDE_LABEL: 'Spoor {{index}} van {{slidesLength}}.',
  START: 'Start',
  ROLES: 'Rollen',
}
