import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Box, Chip, Container, Link as MuiLink } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcIcon, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'
import { ISearchResult } from '@typings'

import styles from './ac-search-result.module.scss'

export const AcSearchResult = observer(
  ({
    name,
    description,
    chips,
    type,
    link,
    slug,
    id,
    milestone,
  }: ISearchResult) => {
    const getIcon = (type: string) => {
      switch (type) {
        case 'track':
          return ICONS.STACKED_LINE
        case 'milestone':
          return ICONS.PLACE
        default:
          return ICONS.STACKED_LINE
      }
    }

    const renderContent = useMemo(() => {
      return (
        <Box
          className={styles['ac-search-result']}
          sx={({ palette }) => ({
            textDecoration: 'none',
            border: `1px solid ${palette.grey[100]}`,
            '&:hover': {
              borderColor: palette.primary.light,
              borderBottom: `2px solid ${palette.primary.light}`,
              marginBottom: 0,
            },
          })}>
          <Container
            disableGutters
            sx={({ palette }) => ({
              display: 'flex',
              justifyContent: 'space-between',
              color: palette.primary.light,
            })}>
            <AcTypography
              className={styles['ac-search-result__title']}
              size="l"
              weight="bold">
              {name && name}
            </AcTypography>
            {type !== 'term' && (
              <AcIcon
                icon={
                  type === 'download' ? ICONS.DOWNLOAD : ICONS.ARROW_FORWARD
                }
              />
            )}
          </Container>
          <AcTypography>{description}</AcTypography>
          {chips && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                marginTop: '1rem',
                flexWrap: 'wrap',
              }}>
              {Object.entries(chips).map(chip => (
                <Chip
                  icon={<AcIcon icon={getIcon(chip[0])} />}
                  label={
                    <>
                      <b>
                        {LABELS[chip[0].toUpperCase() as keyof typeof LABELS]}:{' '}
                      </b>
                      {chip[1]}
                    </>
                  }
                />
              ))}
            </Box>
          )}
        </Box>
      )
    }, [chips, type, name, description])

    return useMemo(() => {
      switch (type) {
        case 'download':
          return (
            <MuiLink
              sx={{
                textDecoration: 'none',
                color: 'initial',
              }}
              target="_blank"
              href={link}>
              {renderContent}
            </MuiLink>
          )
        case 'term':
          return renderContent
        default:
          return (
            <Link
              to={
                type === 'audience' ? `/milestones/${slug}` : `/${type}/${slug}`
              }
              style={{
                textDecoration: 'none',
                color: 'initial',
              }}>
              {renderContent}
            </Link>
          )
      }
    }, [type, slug])
  },
)
