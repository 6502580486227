import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IMenuItem, ISingleMilestone } from '@typings'

export class PagesService extends AxiosBaseApi {
  getAll = () =>
    this.api
      .get<AxiosResponse<any>>(ENDPOINTS.PAGES.ALL())
      .then(response => response.data.data)

  getSingle = (slug: string) =>
    this.api
      .get<AxiosResponse<any>>(ENDPOINTS.PAGES.SINGLE(slug))
      .then(response => response.data.data)
}
