import { useEffect, useRef } from 'react'
import { Box, IconButton, Link } from '@mui/material'

import { AcIcon, AcTypography, ConentenpageContentBlock } from '@components'
import { CONTACT_DRAWER, ICONS } from '@constants'
import { usePiwik } from '@hooks/use-piwik'

// Styles
import styles from './ac-drawer-contact.module.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@hooks/use-store'

export const AcDrawerContact = observer(() => {
  const listRef = useRef<null | HTMLUListElement>(null)
  const { trackContactClick } = usePiwik()
  const { pages, drawer } = useStore()

  const handleClick = (e: any) => {
    if (e.target.id === 'phone-link') trackContactClick('phone')
    if (e.target.id === 'email-link') trackContactClick('email')
  }

  useEffect(() => {
    pages.fetchContact()

    listRef.current?.addEventListener('click', handleClick)
    return () => listRef.current?.removeEventListener('click', handleClick)
  }, [])

  return (
    <Box>
      <IconButton
        onClick={() => drawer.close()}
        sx={{
          position: 'absolute',
          right: '1.5rem',
        }}>
        <AcIcon icon={ICONS.CLOSE} />
      </IconButton>
      {pages.contact?.blocks &&
        pages.contact.blocks.map(singleBlock => (
          <ConentenpageContentBlock
            key={singleBlock.id}
            {...singleBlock}
          />
        ))}
    </Box>
  )
})
