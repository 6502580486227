import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterIcon from '@mui/icons-material/TuneOutlined'
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcContainer,
  AcIcon,
  AcPageWrapper,
  AcSearchFilters,
  AcSearchResults,
  AcTitle,
} from '@components'
import { ICONS, LABELS } from '@constants'
import { getSearchParams } from '@helpers/get-search-params'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'

import styles from './search-view.module.scss'

export const SearchView = observer(() => {
  const { search } = useStore()
  const isMd = useIsMd()
  const [searchParams, setSearchParams] = useSearchParams()

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    search.setPage(1)
    await search.search()
  }

  const renderSearchBox = useMemo(() => {
    return (
      <Box
        component="form"
        onSubmit={onSubmit}>
        <TextField
          className={styles['search-box']}
          fullWidth
          sx={{ mt: 2 }}
          value={search.current_query_params.s}
          placeholder={LABELS.SEARCH_PLACEHOLDER}
          onChange={e => search.setQuery(e.target.value as string)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AcIcon icon={ICONS.SEARCH} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }, [])

  useEffect(() => {
    search.parseQueryParams(searchParams)
    search.search()

    return () => {
      search.setQuery('')
    }
  }, [])

  useEffect(() => {
    setSearchParams(getSearchParams(search.current_query_params))
  }, [search.current_items])

  return (
    <AcPageWrapper>
      <AcContainer maxWidth={1110}>
        <Box
          ml={isMd ? 8 : 0}
          mr={isMd ? 8 : 0}
          mb={6}
          mt={6}>
          <Grid
            sx={{
              flexDirection: 'column',
            }}
            container
            spacing={3}>
            <Grid item>
              <AcTitle
                level={1}
                color={'primary'}>
                {LABELS.SEARCH}
              </AcTitle>
            </Grid>
            <Grid
              item
              sx={{
                flexGrow: 1,
              }}>
              {renderSearchBox}
            </Grid>
            <Grid
              item
              sx={{
                flexGrow: 1,
              }}>
              {!isMd && (
                <Button
                  onClick={() => search.toggle()}
                  fullWidth
                  className={styles['filter-button']}
                  variant="contained"
                  startIcon={<FilterIcon />}>
                  Filter
                </Button>
              )}
            </Grid>
            <Grid item>
              <Container
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: { md: 'row', xs: 'column' },
                  flex: '1 0 auto',
                  gap: '2.5rem',
                  alignItems: 'stretch',
                  padding: '0 !important ',
                }}>
                {isMd && <AcSearchFilters />}
                <AcSearchResults />
              </Container>
            </Grid>
          </Grid>
        </Box>
      </AcContainer>
    </AcPageWrapper>
  )
})
