import { observer } from 'mobx-react-lite'
import { Avatar, Box } from '@mui/material'

// @ts-ignore
import quoteArrow from '../../../assets/visuals/quote-arrow.svg'

import styles from './ac-quote.module.scss'
import { AcTypography } from '../ac-typography/ac-typography'
import { ISingleContentBlockQuote } from '@typings'

export const AcQuote = observer(
  ({ author, content, title }: ISingleContentBlockQuote) => {
    if (!content) {
      return null
    }

    return (
      <Box maxWidth={431}>
        <Box
          className={styles['ac-quote']}
          p={2}
          sx={{
            borderRadius: '9px',
            position: 'relative',
          }}>
          <AcTypography
            size="l"
            mb={0}
            html
            weight="bold">
            {content}
          </AcTypography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '2rem',
          }}>
          <Box
            component="img"
            alt=""
            src={quoteArrow}
          />

          <Box
            sx={{
              display: 'flex',
              alignSelf: 'flex-end',
              alignItems: 'center',
              gap: '0.5rem',
            }}>
            <Avatar
              sx={{
                height: 32,
                width: 32,
              }}
            />
            {author}
          </Box>
        </Box>
      </Box>
    )
  },
)
