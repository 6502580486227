import { observer } from 'mobx-react-lite'
import { AcCard, AcContainer, AcTitle, AcTypography } from '@components'
import { Box } from '@mui/material'
import { ISingleContentBlockCard } from '@typings'
import { useMemo } from 'react'

interface IAcCards extends ISingleContentBlockCard {
  home?: boolean
}

export const AcCards = observer(
  ({ title, content, rows, home = false }: IAcCards) => {
    const renderRows = useMemo(() => {
      return rows.map((row, index) => <AcCard {...row} />)
    }, [rows])

    const renderText = useMemo(() => {
      if (home) {
        return (
          <Box
            sx={{
              textAlign: 'center',
            }}>
            <AcTitle
              level={2}
              color="primary">
              {title}
            </AcTitle>
            <AcTypography>{content}</AcTypography>
          </Box>
        )
      }

      return (
        <Box>
          <AcTitle
            level={2}
            color="primary">
            {title}
          </AcTitle>
          <AcTypography>{content}</AcTypography>
        </Box>
      )
    }, [content, title, home])

    const renderContent = useMemo(() => {
      return (
        <>
          {renderText}
          <Box
            sx={{
              display: 'grid',
              gap: '1.5rem',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
            }}>
            {renderRows}
          </Box>
        </>
      )
    }, [])

    if (home) {
      return (
        <Box
          sx={{
            background: ' var(--ac-palette-primary-100)',
            paddingBlock: '64px',
          }}>
          <AcContainer maxWidth={1152}>{renderContent}</AcContainer>
        </Box>
      )
    }

    return renderContent
  },
)
