import { ISingleAudience, ISingleTrack } from '@typings'

const AUDIENCES = '/audiences'
const MILESTONES = '/milestones'
const TRACKS = '/tracks'
const TRACK = '/track'
const TERMS = '/terms'
const PUBLIC = '/public'
const MENUS = '/menus'
const SEARCH = '/search'
const PAGES = '/pages'

export const ENDPOINTS = {
  MILESTONES: {
    SINGLE: (SLUG: ISingleTrack['slug']) =>
      `${PUBLIC}${TRACK}${MILESTONES}/${SLUG}`,
  },
  TRACKS: {
    ALL: `${PUBLIC}${TRACKS}`,
  },
  AUDIENCE: {
    SINGLE: (SLUG: ISingleAudience['slug']) =>
      `${PUBLIC}${TRACK}${AUDIENCES}/${SLUG}`,
  },
  TERMS: {
    ALL: () => `${PUBLIC}${TERMS}`,
  },
  MENUS: {
    ALL: () => `${PUBLIC}${MENUS}`,
  },
  SEARCH: {
    ALL: () => `${PUBLIC}${SEARCH}`,
  },
  PAGES: {
    ALL: () => `${PUBLIC}${PAGES}`,
    SINGLE: (slug: string) => `${PUBLIC}${PAGES}/${slug}`,
  },
}
