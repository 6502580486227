import { AudienceService } from './audience.services'
import { MilestonesService } from './milestones.services'
import { TracksService } from './tracks.services'
import { TermsService } from './terms.services'
import { MenusService } from './menus.services'
import { SearchService } from './search.services'
import { PagesService } from './pages.services'

export default () => ({
  tracks: new TracksService(),
  milestones: new MilestonesService(),
  audiences: new AudienceService(),
  terms: new TermsService(),
  menus: new MenusService(),
  search: new SearchService(),
  pages: new PagesService(),
})
