export const KEYS = {
  MILESTONES: 'milestones',
  TRACKS: 'tracks',
  HOME: 'home',
  LOADING: 'loading',
  ALL: 'all',
  SINGLE: 'single',
  OPEN: 'open',
  ID: 'id',
  NAME: 'name',
  CONTENT: 'content',
  TITLE: 'title',
  SELECTED_TRACK_ID: 'selected_track_id',
  SELECTED_MILESTONE_ID: 'selected_milestone_id',
  GENERAL_TERMS: 'general_terms',
  PAGE_TERMS: 'page_terms',
  ERROR: 'error',
  SEARCH_VALUE: 'searchValue',
  SLUG: 'slug',
} as const

export const STORE_KEYS = {
  TRACKS: 'tracks',
  AUDIENCES: 'audiences',
  MILESTONES: 'milestones',
  DRAWER: 'drawer',
  TERMS: 'terms',
  NAVIGATION: 'navigation',
  SEARCH: 'search',
  PAGES: 'pages',
} as const
