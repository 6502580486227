import { useMemo } from 'react'
import { Box } from '@mui/material'
import clsx from 'clsx'

import { ISingleContentBlockEmbed } from '@typings'

import styles from './ac-embed.module.scss'

interface IAcEmbed {
  type: ISingleContentBlockEmbed['type']
  src: ISingleContentBlockEmbed['src']
  title?: string
  width?: number | string
  height?: number | string
  className?: string
  home?: boolean
}

export const AcEmbed = ({
  type,
  src,
  title,
  width = '100%',
  height = 0,
  className,
  home = false,
}: IAcEmbed) => {
  if (!type) {
    console.warn('You have not specified an embed type')
    return null
  }

  const generateClasses = useMemo(
    () =>
      clsx(
        styles['ac-embed'],
        styles[`ac-embed--${type}`],
        className && className,
        home && styles['ac-embed--home'],
      ),
    [type, home],
  )

  const renderContent = useMemo(() => {
    return (
      <Box className={generateClasses}>
        <iframe
          className={styles['ac-embed__iframe']}
          width={width}
          height={height}
          src={src}
          title={title}></iframe>
      </Box>
    )
  }, [type, src, title, width, height])

  if (home) {
    return (
      <Box
        sx={{
          paddingBlock: '64px',
        }}>
        {renderContent}
      </Box>
    )
  }

  return renderContent
}
