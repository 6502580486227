import { AcDefaultLayout, AcNoHeaderLayout } from '@layouts'
import {
  ContentView,
  GenericView,
  HomeView,
  SearchView,
  StylesView,
  TracksView,
} from '@views'

import { KEYS } from './keys.constants'

export const ROUTE_PATHS = {
  HOME: '/',
  TRACKS: '/tracks',
  CONTENT_PAGE: '/milestones/:trackId/:milestoneId/:audienceId',
  STYLES: '/styles',
  SEARCH: '/search',
  SLUG: '*',
}

export const ROUTES = [
  {
    id: KEYS.HOME,
    path: ROUTE_PATHS.HOME,
    element: <AcNoHeaderLayout />,
    children: [
      {
        index: true,
        element: <HomeView />,
      },
      {
        path: ROUTE_PATHS.SEARCH,
        element: <SearchView />,
      },
    ],
  },
  {
    path: ROUTE_PATHS.STYLES,
    element: <StylesView />,
  },
  {
    path: '/',
    element: <AcDefaultLayout />,
    children: [
      {
        id: KEYS.TRACKS,
        path: ROUTE_PATHS.TRACKS,
        layout: <AcDefaultLayout />,
        element: <TracksView />,
      },
      {
        path: ROUTE_PATHS.CONTENT_PAGE,
        layout: <AcDefaultLayout />,
        element: <ContentView />,
      },
    ],
  },
  {
    path: '/',
    element: <AcNoHeaderLayout />,
    children: [
      {
        path: ROUTE_PATHS.SLUG,
        element: <GenericView />,
      },
    ],
  },
  /** [ ROUTES ]
   * You can add routes by doing:
   * `hygen make route`
   */
]
