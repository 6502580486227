import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import {
  ISingleTrack,
  ISingleTrackMilestone,
  ITracks,
  ISingleTrackRow,
} from '@typings'

export class TracksStore {
  store: Store
  all: ITracks
  selected_track_id: ISingleTrack['slug'] | null
  selected_milestone_id: ISingleTrackMilestone['slug'] | null
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.selected_track_id = null
    this.selected_milestone_id = null
    this.all = []
  }

  @computed
  get current_selected_track_id(): ISingleTrack['slug'] | null {
    // if (!this.has_data) return null
    return this.selected_track_id
  }

  @computed
  get current_selected_milestone_id(): ISingleTrackMilestone['slug'] | null {
    return this.selected_milestone_id
  }

  @computed
  get has_data(): boolean {
    return !!(this.all && this.all.length > 0)
  }

  @action
  updateSelectedId = (entity: 'milestones' | 'tracks', slug: string) => {
    if (entity === KEYS.MILESTONES) {
      if (slug !== this.selected_milestone_id)
        this.store.set(STORE_KEYS.TRACKS, KEYS.SELECTED_MILESTONE_ID, slug)
    } else {
      if (slug !== this.selected_track_id) {
        this.store.set(STORE_KEYS.TRACKS, KEYS.SELECTED_TRACK_ID, slug)
        this.store.set(STORE_KEYS.TRACKS, KEYS.SELECTED_MILESTONE_ID, null)
      }
    }
  }

  @action
  getAll = async () => {
    // If this.all is filled, we don't re-execute this call.
    if (this.has_data) return
    this.store.set(STORE_KEYS.TRACKS, KEYS.LOADING, true)
    try {
      const tracks = await this.store.services.tracks.getAll()
      const sortedTracks = this.sortTracks(tracks)
      this.store.set(STORE_KEYS.TRACKS, KEYS.ALL, sortedTracks)
      if (tracks.length === 1) {
        this.store.set(
          STORE_KEYS.TRACKS,
          KEYS.SELECTED_TRACK_ID,
          tracks[0].slug,
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.store.set(STORE_KEYS.TRACKS, KEYS.LOADING, false)
    }
  }

  @action
  // First level
  sortTracks = (tracks: ITracks) => {
    const sortRowsAndColumns = tracks.map(track => {
      const sortedColumns = track.rows.map(row => this.sortColumnns(row))
      const sortedRows = this.sortRows(sortedColumns)
      return {
        ...track,
        rows: sortedRows,
      }
    })
    const sortedTracks = sortRowsAndColumns.sort((a, b) => a.sort - b.sort)
    return sortedTracks
  }

  @action
  // Second level
  sortRows = (rows: ISingleTrackRow[]) => {
    return rows.sort((a, b) => a.sort - b.sort)
  }

  @action
  // Third level
  sortColumnns = (column: ISingleTrackRow) => {
    return {
      ...column,
      milestones: column.milestones.sort((a, b) => a.column - b.column),
    }
  }

  @action
  reset = () => {
    this.store.set(STORE_KEYS.TRACKS, KEYS.ALL, [])
    this.store.set(STORE_KEYS.TRACKS, KEYS.SELECTED_TRACK_ID, null)
    this.store.set(STORE_KEYS.TRACKS, KEYS.SELECTED_MILESTONE_ID, null)
    this.store.set(STORE_KEYS.TRACKS, KEYS.LOADING, false)
  }
}
