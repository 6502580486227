import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { ISingleAudience } from '@typings'

const defaultValues: Record<string, any> = {
  loading: true,
  single: null,
  selected: null,
}

export class AudiencesStore {
  store: Store
  single: ISingleAudience | null
  loading: boolean
  selected: string

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = defaultValues['loading']
    this.single = defaultValues['single']
    this.selected = defaultValues['selected']
  }

  @computed
  get is_loading(): boolean {
    return this.loading
  }

  @action
  setCurrent = (slug: ISingleAudience['slug']) => {
    this.selected = slug
  }

  @computed
  get has_data(): boolean {
    return !!this.single && this.single !== null
  }

  @computed
  get has_links(): boolean {
    if (this.has_data) {
      return !!(this.single && this.single.links && this.single.links.length)
    }
    return false
  }

  @computed
  get current_links(): ISingleAudience['links'] | null {
    if (this.single && this.single.links) return this.single?.links
    return null
  }

  @computed
  get current_id(): Nullable<ISingleAudience['slug']> {
    return this.single?.slug ?? null
  }

  @action
  setEmpty = () => {
    //This just sets loading to false to have this.single be null
    this.reset()
    this.store.set(STORE_KEYS.AUDIENCES, KEYS.LOADING, false)
  }

  @action
  getSingle = async (slug: ISingleAudience['slug']) => {
    if (slug !== null && slug === this.current_id) return
    try {
      if (!slug) throw new Error('no Id')
      this.store.set(STORE_KEYS.AUDIENCES, KEYS.LOADING, true)
      const single = await this.store.services.audiences.getSingle(slug)
      this.store.set(STORE_KEYS.AUDIENCES, KEYS.SINGLE, single)
      if (single.terms) {
        this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, single.terms)
      } else {
        this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, null)
      }
    } catch (e) {
      console.error(e)
      this.store.set(STORE_KEYS.TERMS, KEYS.PAGE_TERMS, null)
    } finally {
      this.store.set(STORE_KEYS.AUDIENCES, KEYS.LOADING, false)
    }
  }

  @action
  reset = () => {
    Object.keys(defaultValues).map(singleKey => {
      this.store.set(
        STORE_KEYS.AUDIENCES,
        singleKey as keyof AudiencesStore,
        defaultValues[singleKey],
      )
    })
  }
}
