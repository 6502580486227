import { List, ListItem } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcSidemenuItem } from '@components'
import { useIsMd } from '@hooks/use-isMd'
import { useLocation, useNavigate } from 'react-router-dom'

interface IAcGenericViewAsideMenu {
  items: any[]
  isChild?: boolean
}

export const AcGenericViewAsideMenu = observer(
  ({ items }: IAcGenericViewAsideMenu) => {
    const navigate = useNavigate()
    const location = useLocation()
    const isMd = useIsMd()

    return (
      isMd && (
        <List
          role={'menu'}
          sx={{ minWidth: 244 }}>
          {items.map(menuItem => (
            <ListItem
              key={menuItem.id}
              role={'menuitem'}
              disablePadding>
              <AcSidemenuItem
                active={location.pathname.substring(1) === menuItem.slug}
                onClick={() => navigate(`/${menuItem.slug}`)}
                label={menuItem.name}
              />
            </ListItem>
          ))}
        </List>
      )
    )
  },
)
