import { Alert, Box, Pagination, Skeleton } from '@mui/material'
import { AcSearchResult } from '../ac-search-result/ac-search-result'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { ISearchResult } from '@typings'
import React, { useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const AcSearchResults = observer(() => {
  const navigate = useNavigate()
  const { search } = useStore()

  const handlePaginationChange = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    search.setPage(value)
    await search.search()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  const renderSearchResults = useMemo(() => {
    if (search.is_loading) {
      // return 7 skeletons
      return Array.from({ length: 7 }).map((_, index) => (
        <Skeleton
          variant="rectangular"
          height={210}
        />
      ))
    }

    if (search.current_items.length <= 0 && !search.is_loading) {
      return (
        <Box
          sx={{
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Alert
            color="warning"
            severity="warning">
            {'Er zijn geen zoekresultaten gevonden met de gekozen filters.'}
          </Alert>
        </Box>
      )
    }

    return search.current_items?.map((item: ISearchResult) => (
      <AcSearchResult {...item} />
    ))
  }, [search.current_items, search.is_loading])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}>
      {renderSearchResults}
      <Pagination
        hidePrevButton
        hideNextButton
        count={search.meta?.last_page}
        page={search.current_page}
        color="primary"
        onChange={handlePaginationChange}
      />
    </Box>
  )
})
