import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcContainer, AcIcon, AcTitle, AcTypography } from '@components'
import { ICONS } from '@constants'
import { ISingleContentBlockAccordion } from '@typings'
import { useMemo } from 'react'

import styles from './ac-accordion.module.scss'

interface IAcAccordion extends ISingleContentBlockAccordion {
  hideTitle?: boolean
  home?: boolean
}

export const AcAccordion = observer(
  ({ title, description, hideTitle, rows, home = false }: IAcAccordion) => {
    const renderAccordions = useMemo(() => {
      return rows.map((row, index) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<AcIcon icon={ICONS.ADD} />}
            aria-controls="panel1-content"
            id="panel1-header">
            <AcTypography
              weight="bold"
              mb={0}>
              {row.title}
            </AcTypography>
          </AccordionSummary>
          <AccordionDetails>
            <AcTypography html>{row.content}</AcTypography>
          </AccordionDetails>
        </Accordion>
      ))
    }, [rows])

    const renderContent = useMemo(() => {
      return (
        <>
          {!hideTitle && (
            <Box>
              <AcTitle
                level={2}
                color="primary">
                {title}
              </AcTitle>
              <AcTypography>{description}</AcTypography>
            </Box>
          )}
          <Box>{renderAccordions}</Box>
        </>
      )
    }, [hideTitle, title, description, renderAccordions])

    if (home) {
      return (
        <Box className={styles['ac-accordion--home']}>
          <AcContainer maxWidth={782}>
            <Box className={styles['ac-accordion__content']}>
              <AcTitle
                level={2}
                color="primary">
                {title}
              </AcTitle>
              <AcTypography>{description}</AcTypography>
            </Box>
            <Box>{renderAccordions}</Box>
          </AcContainer>
        </Box>
      )
    }

    return renderContent
  },
)
