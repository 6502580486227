import { Box } from '@mui/material'

import { AcTitle } from '../../core/ac-title/ac-title'
import { AcTypography } from '../../core/ac-typography/ac-typography'

export const AcNotFound = () => {
  return (
    <Box>
      <AcTitle level={1}>Pagina niet gevonden</AcTitle>
      <AcTypography>
        We hebben de opgevraagde pagina niet kunnen vinden.
      </AcTypography>
    </Box>
  )
}
