import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import styles from './ac-app-navigation-dropdown.module.scss'
import { useStore } from '@hooks/use-store'
import { LABELS } from '@constants'
import { AcDrawerContact } from '../ac-drawer-contact/ac-drawer-contact'

export const AcAppNavigationDropdown = observer(() => {
  const { navigation, drawer } = useStore()

  if (!navigation.is_open) {
    return null
  }

  return (
    <Box
      className={styles['ac-app-navigation-dropdown']}
      sx={({ palette }) => ({
        zIndex: theme => theme.zIndex.drawer - 1,
        color: palette.primary.contrastText,
        background: palette.primary.main,
      })}>
      <Typography
        className={styles['nav-link']}
        href="/"
        component="a"
        variant="h2">
        Preventie
      </Typography>
      <Typography
        className={styles['nav-link']}
        href="/"
        component="a"
        variant="h2">
        Roadmaps
      </Typography>
      <Typography
        className={styles['nav-link']}
        href="/"
        component="a"
        variant="h2">
        Toolkit
      </Typography>
      <Box
        sx={{
          textDecoration: 'underline',
        }}
        tabIndex={0}
        role="button"
        onClick={() => {
          drawer.toggleDrawer(LABELS.CONTACT, <AcDrawerContact />)
          navigation.toggle()
        }}>
        <Typography
          className={styles['nav-link']}
          component="span"
          variant="h2">
          Contact
        </Typography>
      </Box>
    </Box>
  )
})
