import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { IMenuItem, ISingleMilestone } from '@typings'

export class MenusService extends AxiosBaseApi {
  getAll = () =>
    this.api
      .get<AxiosResponse<{ data: IMenuItem[] }>>(ENDPOINTS.MENUS.ALL())
      .then(response => response.data.data)
}
