// Components
import { createPortal } from 'react-dom'
import { Box, Drawer, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { AcIcon, AcSearchFilters, AcTypography } from '@components'
import { ICONS } from '@constants'
import { useIsMd, useStore } from '@hooks/index'

import styles from './ac-search-drawer.module.scss'

export const AcSearchDrawer = observer(() => {
  const { search } = useStore()

  return (
    <Drawer
      PaperProps={{
        sx: {
          boxSizing: 'border-box',
          height: '100vh',
          overflowY: 'auto',
          paddingTop: '64px',
        },
      }}
      onClose={() => search.close()}
      ModalProps={{ onBackdropClick: () => search.close() }}
      anchor="bottom"
      open={search.is_open}>
      <AcSearchFilters />
    </Drawer>
  )
})
