import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcAlert,
  AcContentContainer,
  AcIcon,
  AcPageWrapper,
  AcTitle,
  ConentenpageContentBlock,
  ContentpageAsideMenu,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const ContentView = observer(() => {
  const { trackId, milestoneId, audienceId } = useParams()
  const { audiences, tracks, milestones, terms } = useStore()

  useEffect(() => {
    if (trackId) {
      const _slug = trackId
      if (_slug !== tracks.current_selected_track_id) {
        tracks.updateSelectedId(KEYS.TRACKS, _slug)
      }
    }
    if (milestoneId) {
      const _slug = milestoneId
      if (_slug !== tracks.current_selected_milestone_id) {
        tracks.updateSelectedId(KEYS.MILESTONES, _slug)
      }
      milestones.getSingle(_slug)
    }

    if (audienceId) {
      audiences.setCurrent(audienceId)
      audiences.getSingle(audienceId)
    }
  }, [trackId, milestoneId, audienceId])

  useEffect(() => {
    return () => {
      audiences.reset()
      terms.clearCurrentPageTerms()
    }
  }, [])

  return (
    <AcPageWrapper>
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          flex: '1 0 auto',
          gap: '2.5rem',
          alignItems: 'stretch',
          padding: '3rem 0',
        }}>
        <ContentpageAsideMenu />
        <AcContentContainer loading={audiences.loading}>
          {!audiences.has_data && (
            <AcAlert
              label={LABELS.NO_DATA}
              color="info"
              icon={<AcIcon icon={ICONS.INFO} />}
            />
          )}
          {audiences.single?.name && (
            <AcTitle
              level={1}
              color={'primary'}>
              {audiences.single.name}
            </AcTitle>
          )}
          {audiences.single?.blocks &&
            audiences.single.blocks.map(singleBlock => (
              <ConentenpageContentBlock
                key={singleBlock.id}
                {...singleBlock}
              />
            ))}
        </AcContentContainer>
      </Container>
    </AcPageWrapper>
  )
})
