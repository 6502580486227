import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
// Components
import { Box, Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcContainer,
  AcContentContainer,
  AcNotFound,
  AcPageWrapper,
  AcSelect,
  AcTitle,
  AcTypography,
  ConentenpageContentBlock,
} from '@components'
// Content
import { HEADER, KEYS, LABELS, ROUTE_PATHS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'
import { ISingleTrackMilestone } from '@typings'

// Styles
import styles from './home-view.module.scss'

export const HomeView = observer(() => {
  const { tracks, pages, milestones } = useStore()
  const navigate = useNavigate()
  const isMd = useIsMd()
  const [openMilestoneDropdown, setOpenMilestoneDropdown] = useState(false)
  const [allowSearch, setAllowSearch] = useState(false)

  const handleOpenMilestoneDropdown = () => setOpenMilestoneDropdown(true)
  const handleCloseMilestoneDropdown = () => setOpenMilestoneDropdown(false)

  const getCurrentMilestoneOptions = useMemo(() => {
    const arr: ISingleTrackMilestone[] = []
    if (!tracks.current_selected_track_id || !tracks.has_data) return arr

    const currentTrack = tracks.all.find(
      ({ slug }) => slug === tracks.current_selected_track_id,
    )

    if (!currentTrack) return arr

    return (
      currentTrack &&
      currentTrack.rows.reduce((bulk, curr) => {
        for (const milestone of curr.milestones) {
          bulk.push(milestone)
        }
        return bulk
      }, arr)
    )
  }, [tracks.current_selected_track_id])

  useEffect(() => {
    if (
      tracks.current_selected_milestone_id &&
      tracks.current_selected_track_id
    ) {
      setAllowSearch(true)
    } else {
      setAllowSearch(false)
    }
  }, [tracks.current_selected_milestone_id])

  useEffect(() => {
    pages.fetchPage('home')

    if (tracks.has_data) return
    tracks.getAll()
  }, [])

  // Methods
  const handleSearch = () => {
    if (allowSearch)
      navigate(
        generatePath(ROUTE_PATHS.CONTENT_PAGE, {
          trackId: tracks.current_selected_track_id,
          milestoneId: tracks.current_selected_milestone_id,
          audienceId: getCurrentMilestoneOptions.find(
            x => x.slug === tracks.current_selected_milestone_id,
          )?.audiences[0].slug,
        }),
      )
  }

  return (
    <AcPageWrapper home>
      <Box className={styles['header']}>
        <AcContainer maxWidth={1152}>
          <AcTitle
            level={1}
            className={styles['header__title']}>
            {HEADER.TITLE}
          </AcTitle>
          <AcTypography className={styles['header__intro']}>
            {HEADER.CONTENT}
          </AcTypography>
          <Grid
            container
            spacing={1.5}
            mt={3.5}>
            <Grid
              item
              sm={12}
              md={5}>
              <AcSelect
                options={tracks.all}
                selectLabel={LABELS.TRACK}
                labelKey={KEYS.NAME}
                valueKey={KEYS.SLUG}
                readOnly={tracks.all.length === 1}
                sx={({ palette }) => ({
                  backgroundColor: palette.common.white,
                })}
                onChangeCallback={e => {
                  isMd && handleOpenMilestoneDropdown()
                  tracks.updateSelectedId(KEYS.TRACKS, e as string)
                }}
                value={tracks.current_selected_track_id}
                key={tracks.current_selected_track_id ?? 'A'}
              />
              {tracks.all.length === 1 && (
                <div style={{ marginTop: 8 }}>
                  <AcTypography size="s">
                    {LABELS.WORKING_ON_NEW_CONTENT}
                  </AcTypography>
                </div>
              )}
            </Grid>
            <Grid
              item
              sm={12}
              md={5}>
              <AcSelect
                open={openMilestoneDropdown}
                onOpen={handleOpenMilestoneDropdown}
                onClose={handleCloseMilestoneDropdown}
                selectLabel={LABELS.MILESTONE}
                sx={({ palette }) => ({
                  backgroundColor: palette.common.white,
                })}
                options={getCurrentMilestoneOptions}
                key={tracks.current_selected_milestone_id ?? 'B'}
                labelKey={KEYS.NAME}
                valueKey={KEYS.SLUG}
                value={tracks.current_selected_milestone_id}
                onChangeCallback={e =>
                  tracks.updateSelectedId(KEYS.MILESTONES, e as string)
                }
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={2}>
              <AcButton
                color="secondary"
                label={HEADER.START_SEARCH}
                disabled={!allowSearch}
                onClick={handleSearch}
                fullWidth
              />
            </Grid>
          </Grid>
        </AcContainer>
      </Box>
      <Box className={styles['body']}>
        <AcContentContainer loading={pages.loading}>
          {!pages.single && <AcNotFound />}
          {pages.single?.name && (
            <Box
              sx={{
                textAlign: 'center',
              }}>
              <AcTitle
                level={1}
                color={'primary'}>
                {pages.single?.name}
              </AcTitle>
            </Box>
          )}
          {pages.single?.blocks &&
            pages.single.blocks.map((singleBlock: any) => (
              <ConentenpageContentBlock
                home
                key={singleBlock.id}
                {...singleBlock}
              />
            ))}
        </AcContentContainer>
      </Box>
    </AcPageWrapper>
  )
})
