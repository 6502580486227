import { useEffect, useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { clsx } from 'clsx'
import { observer } from 'mobx-react-lite'
// Swiper
import { A11y, Navigation } from 'swiper/modules'
import type { SwiperRef } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AcPageWrapper, AcSwiperButton, AcTrack } from '@components'
// Misc
import { LABELS } from '@constants'
import { useIsMd } from '@hooks/use-isMd'
import { useStore } from '@hooks/use-store'

import 'swiper/css/navigation'

// Styles
import 'swiper/css'
import styles from './tracks.module.scss'

export const TracksView = observer(() => {
  const { tracks } = useStore()
  const isMd = useIsMd()
  const swiperRef = useRef<null | SwiperRef>(null)

  const selectedTracks = useMemo(() => {
    if (tracks.current_selected_track_id) {
      return tracks.all.filter(
        track => track.slug === tracks.current_selected_track_id,
      )
    }
    return tracks.all
  }, [tracks.all, tracks.current_selected_track_id])

  // State
  const [tracksPerView, setTracksPerView] = useState(3.2)
  const [showBottomFade, setShowBottomFade] = useState(false)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const showSwiperNavigation = useMemo(() => {
    return selectedTracks.length > tracksPerView
  }, [selectedTracks, tracksPerView])

  // Fade
  const showLeftFade = useMemo(() => {
    return showSwiperNavigation && isMd && !isBeginning
  }, [showSwiperNavigation, isMd, isBeginning])
  const showRightFade = useMemo(
    () => showSwiperNavigation && isMd && !isEnd,
    [showSwiperNavigation, isMd, isEnd],
  )
  const fadeLeftClass = useMemo(
    () =>
      clsx(
        styles['ac-swiper-fade'],
        styles['ac-swiper-fade--left'],
        showLeftFade && styles['ac-show-swiper-fade'],
      ),
    [showLeftFade],
  )

  const fadeRightClass = useMemo(
    () =>
      clsx(
        styles['ac-swiper-fade'],
        styles['ac-swiper-fade--right'],
        showRightFade && styles['ac-show-swiper-fade'],
      ),
    [showRightFade],
  )

  const bottomFadeClass = useMemo(
    () => clsx(showBottomFade && styles['ac-swiper-fade--bottom']),
    [showBottomFade],
  )

  const handleResize = () => {
    if (window.innerWidth < 872) setTracksPerView(1)
    if (window.innerWidth >= 872) setTracksPerView(2)
    if (window.innerWidth >= 1400) setTracksPerView(3.2)
    if (window.innerWidth >= 1800) setTracksPerView(4.2)
    if (window.innerWidth >= 2000) setTracksPerView(5.2)

    setTimeout(() => {
      const navbarHeight = 80
      const windowHeight = window.innerHeight - navbarHeight
      // todo: any
      const trackHeight = (swiperRef.current as any)?.clientHeight
      if (trackHeight > windowHeight) {
        setShowBottomFade(true)
      } else {
        setShowBottomFade(false)
      }
    }, 500)
  }

  const handleScroll = () => {
    const scrolledToBottom =
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.scrollHeight
    if (scrolledToBottom) {
      setShowBottomFade(false)
    } else {
      !showBottomFade && setShowBottomFade(true)
    }
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    setTimeout(() => {
      const trackNames = tracks.all.map((t, i) => ({ name: t.name, order: i }))
      const currentTrack = document.activeElement?.id || ''
      const trackIsFocused = trackNames.find(t => t.name === currentTrack)

      if (trackIsFocused) {
        if (e.key === 'ArrowRight') {
          const nextTrack = trackNames.find(
            t => t.order === trackIsFocused.order + 1,
          )
          if (nextTrack) {
            const nextTrackEl = document.getElementById(nextTrack.name)
            nextTrackEl?.focus()
            window.scrollTo(0, 0)
          }
        }
        if (e.key === 'ArrowLeft') {
          const prevTrack = trackNames.find(
            t => t.order === trackIsFocused.order - 1,
          )
          if (prevTrack) {
            const prevTrackEl = document.getElementById(prevTrack.name)
            prevTrackEl?.focus()
            window.scrollTo(0, 0)
          }
        }
      }
    }, 50)
  }

  useEffect(() => {
    tracks.getAll()
    handleResize()
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    } as unknown as EventListenerOptions)
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll, {
        passive: true,
      } as unknown as EventListenerOptions)
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  return (
    <AcPageWrapper loading={tracks.loading}>
      <Swiper
        tag="ul"
        tabIndex={0}
        modules={[Navigation, A11y]}
        className={styles['ac-swiper']}
        navigation={{
          prevEl: '#prev-slide-button',
          nextEl: '#next-slide-button',
        }}
        a11y={{
          enabled: true,
          prevSlideMessage: LABELS.VIEW_PREV_TRACK,
          nextSlideMessage: LABELS.VIEW_NEXT_TRACK,
          containerMessage:
            LABELS.A11Y_SWIPER_CONTAINER_MESSAGE + selectedTracks.length,
          containerRoleDescriptionMessage: 'Group',
          itemRoleDescriptionMessage: 'Group Item',
          lastSlideMessage: LABELS.A11Y_SWIPER_LAST_SLIDE_MESSAGE,
          slideLabelMessage: LABELS.A11Y_SWIPER_SLIDE_LABEL,
        }}
        centeredSlides={selectedTracks.length === 1}
        centeredSlidesBounds={selectedTracks.length === 1}
        spaceBetween={24}
        onSlideChange={swiper => {
          setIsBeginning(swiper.isBeginning)
          setIsEnd(swiper.isEnd)
        }}
        slidesPerView={1}
        breakpoints={{
          872: {
            slidesPerView: 2,
          },
          1400: {
            slidesPerView: 3.2,
          },
          1800: {
            slidesPerView: 4.2,
          },
          2000: {
            slidesPerView: 5.2,
          },
        }}
        ref={swiperRef}
        style={{
          marginInline: selectedTracks.length === 1 ? 'auto' : 0,
        }}>
        <Box className={fadeLeftClass}></Box>
        <Box className={fadeRightClass}></Box>
        <Box className={bottomFadeClass}></Box>
        <Box className={styles['ac-swiper-controls']}>
          <AcSwiperButton
            direction="prev"
            id="prev-slide-button"
            className={styles['ac-swiper-navigation']}
            label={LABELS.MORE_TRACKS}
          />
          <AcSwiperButton
            direction="next"
            id="next-slide-button"
            className={styles['ac-swiper-navigation']}
            label={LABELS.MORE_TRACKS}
          />
        </Box>

        {selectedTracks.map(singleTrack => (
          <SwiperSlide
            tag="li"
            tabIndex={0}
            aria-label={singleTrack.name}
            id={singleTrack.name}
            key={singleTrack.id}
            className={styles['ac-swiper-slide']}>
            <AcTrack track={singleTrack} />
          </SwiperSlide>
        ))}
      </Swiper>
    </AcPageWrapper>
  )
})
